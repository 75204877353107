<template>
  <section class="w-100 mt-5">
    <h2 class="mx-3 mb-3">
      {{ $t('new_booking.tab.active_bookings.logbook_bookings') }}
    </h2>
    <ui-loader v-if="isLoading" />
    <template v-else>
      <template v-if="hasFetchFailed">
        <figure class="d-flex justify-content-center">
          <img
            :src="genericErrorIllustration"
            class="w-50"
            data-test-id="logbook_failed-image"
            alt="logbook_failed-image"
          >
        </figure>
      </template>
      <template v-else>
        <MuiTable
          v-if="size(logbooks)"
          :content-cells="logbookTableConfig"
          :data-set="logbooks"
          :no-data-label="FALLBACK_MESSAGE.dash"
          :is-loading="isLoading"
          data-test-id="logbooks-table"
          class="mx-3"
        />
        <EmptyStateComponent
          v-else
          :src="emptyStateIcon"
          :title="$t('new_booking.tab.active_bookings.empty_state_logbook')"
          has-background
          class="h-100 p-4 d-flex"
        />
      </template>
    </template>
  </section>
</template>
<script>
import size from 'lodash/size';
import { MuiTable } from '@emobg/vue-base';
import { navigationErrorHandler } from '@emobg/web-utils';

import { errorNotification } from '@/handlers/errorHandler.const';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { fetchLogbooks, logbooks } from '@/stores/Logbook/LogbookMapper';

// Routes
import BookingRoutesNames from '@Bookings/router/routes-names';

import { LOGBOOK_TABLE_CONFIG } from '@/components/MyBookings/constants/tableConfig';
import { emptyCarpoolingTrips, genericError } from '@/utils/publicImages';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';

export default {
  name: 'LogBookTab',

  components: {
    EmptyStateComponent,
    MuiTable,
  },

  setup() {
    const { notifyError } = useNotifications();
    return { notifyError };
  },
  data() {
    return {
      isLoading: false,
      hasFetchFailed: false,
    };
  },

  computed: {
    logbooks,
  },

  created() {
    this.loadPendingLogBooks();
    this.genericErrorIllustration = genericError;
    this.emptyStateIcon = emptyCarpoolingTrips;

    const tableActions = {
      logbook: booking => this.$router.push({
        name: BookingRoutesNames.logbook,
        params: { bookingUuid: booking.uuid },
      }).catch(navigationErrorHandler),
    };
    this.logbookTableConfig = LOGBOOK_TABLE_CONFIG(tableActions);
  },

  methods: {
    size,
    fetchLogbooks,

    async loadPendingLogBooks() {
      this.isLoading = true;
      try {
        await this.fetchLogbooks();
      } catch (error) {
        const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError(errorNotification(errorMessage));
        this.hasFetchFailed = true;
      } finally {
        this.isLoading = false;
      }
    },

  },

};
</script>
