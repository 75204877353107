export const PURPOSES = {
  break: 'break',
  wayIn: 'way_in',
  returnTrip: 'return',
};

export const HIDING_FIELDS_PURPOSES = [
  PURPOSES.break,
  PURPOSES.returnTrip,
];
