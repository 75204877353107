import { ICONS, PLACEMENTS } from '@emobg/web-components';

import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const RECURRENCE_OPTIONS = {
  weekly: 'weekly',
  monthly: 'monthly',
  weekday: 'weekday',
  custom: 'custom',
};

export const RECURRENCE_OPTIONS_DATA = [
  {
    label: $t(`BookingView.RecurringBookingsModal.recurrenceOptionsSelect.options.${RECURRENCE_OPTIONS.weekly}.label`),
    value: RECURRENCE_OPTIONS.weekly,
    daysRange: 7,
    props: {
      text: $t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.tooltip', { days: 7 }),
      icon: ICONS.info,
      placement: PLACEMENTS.leftStart,
    },
  },
  {
    label: $t(`BookingView.RecurringBookingsModal.recurrenceOptionsSelect.options.${RECURRENCE_OPTIONS.weekday}.label`),
    value: RECURRENCE_OPTIONS.weekday,
    daysRange: 1,
    props: {
      text: $t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.tooltip', { days: 1 }),
      icon: ICONS.info,
      placement: PLACEMENTS.leftStart,
    },
  },
  {
    label: $t(`BookingView.RecurringBookingsModal.recurrenceOptionsSelect.options.${RECURRENCE_OPTIONS.monthly}.label`),
    value: RECURRENCE_OPTIONS.monthly,
    daysRange: 30,
    props: {
      text: $t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.tooltip', { days: 30 }),
      icon: ICONS.info,
      placement: PLACEMENTS.leftStart,
    },
  },
  {
    label: $t(`BookingView.RecurringBookingsModal.recurrenceOptionsSelect.options.${RECURRENCE_OPTIONS.custom}.label`),
    value: RECURRENCE_OPTIONS.custom,
    daysRange: 7,
    props: {
      text: $t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.tooltip', { days: 7 }),
      icon: ICONS.info,
      placement: PLACEMENTS.leftStart,
    },
  },
];
