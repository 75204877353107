<template>
  <div>
    <ui-modal
      :open="value"
      :size="SIZES.large"
      :header="$t('MyBookings.AddCarpoolingModal.title')"
      closable
      data-test-id="add-carpooling-modal"
      @close="() => $emit('on:close', { isEdited: false })"
    >
      <div slot="body">
        <ui-form validate>
          <ui-validate @status="({ detail }) => formStatus = detail">
            <div class="d-flex flex-column emobg-font-small">
              <CarpoolingOutgoingCard
                :enabled="trips.outBound.enabled"
                :origin-station="locationName"
                :outbound-address="outboundAddress"
                :start-date-time="start"
                :seats="outboundSeats"
                :border="false"
                @change:enabled="value => trips.outBound.enabled = value"
                @change:outbound-address="value => outboundAddress = value"
                @change:seats="value => outboundSeats = value"
              />
              <CarpoolingReturnCard
                :enabled="trips.return.enabled"
                :return-station="locationName"
                :return-address="returnAddress"
                :return-start="returnStart"
                :seats="returnSeats"
                :start-date-time="start"
                :end-date-time="end"
                :border="false"
                @change:enabled="value => trips.return.enabled = value"
                @change:return-address="value => returnAddress = value"
                @change:seats="value => returnSeats = value"
                @change:return-start="value => returnStart = value"
              />
            </div>
          </ui-validate>
        </ui-form>
      </div>
      <div slot="footer">
        <div class="d-flex align-items-center justify-content-end">
          <ui-button
            v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY})"
            :disabled="isLoading"
            class="mr-2"
            data-test-id="close_modal-button"
            @clickbutton="$emit('on:close', { isEdited: false })"
          >
            {{ $t('buttons.cancel') }}
          </ui-button>
          <ui-button
            v-bind="fetchButtonSpecs()"
            :type="BUTTON_TYPES.submit"
            :disabled="!formStatus.areAllValidated || areTripsDisabled"
            :loading="isLoading"
            data-test-id="submit_add_carpooling-button"
            @clickbutton="confirmAddCarpooling"
          >
            {{ $t('MyBookings.AddCarpoolingModal.confirm_button') }}
          </ui-button>
        </div>
      </div>
    </ui-modal>
    <FeedbackModalComponent
      v-model="modal.isOpen"
      v-bind="modal"
    />
  </div>
</template>
<script>
import get from 'lodash/get';
import pick from 'lodash/pick';
import moment from 'moment';
import { mapActions } from 'vuex';
import {
  BUTTON_TYPES,
  Validate,
} from '@emobg/vue-base';
import { DATE_FORMAT, LOG_TYPE, logger } from '@emobg/web-utils';

import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import ALERT_TYPES from '@/components/Alert/alertTypes';

import { defaultHeaderConfig, genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { getCarpoolingCreationParams } from '@/helpers/booking/apiHelpers';
import CarpoolingOutgoingCard from '@/domains/Carpooling/components/CarpoolingOutgoingCard/CarpoolingOutgoingCard';
import CarpoolingReturnCard from '@/domains/Carpooling/components/CarpoolingReturnCard/CarpoolingReturnCard';
import { useTheme } from '@/composable/Theme/useTheme';

import { NAMESPACE as CarpoolingModuleNameSpace } from '@/domains/Carpooling/store/CarpoolingModule';

export default {
  name: 'AddCarpoolingModal',
  components: {
    FeedbackModalComponent,
    CarpoolingOutgoingCard,
    CarpoolingReturnCard,
  },
  directives: {
    Validate,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    booking: {
      type: Object,
      required: true,
    },
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    locationName: {
      type: String,
      required: true,
    },
    availableSeats: {
      type: Number,
      required: true,
    },
    driversCount: {
      type: Number,
      default: 0,
    },
    passengersCount: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
  data() {
    return {
      manager: null,
      trips: {
        outBound: {
          enabled: true,
        },
        return: {
          enabled: true,
        },
      },
      isLoading: false,
      formStatus: {},
      modal: {
        isOpen: false,
      },
    };
  },
  computed: {
    areTripsDisabled() {
      return !this.trips.outBound.enabled && !this.trips.return.enabled;
    },
  },

  watch: {
    value() {
      this.initFormValues();
    },
  },

  created() {
    this.initFormValues();
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.ALERT_TYPES = ALERT_TYPES;
    this.defaultHeaderConfig = defaultHeaderConfig;
  },

  methods: {
    ...mapActions(CarpoolingModuleNameSpace, ['createCarpooling']),
    initFormValues() {
      this.outboundAddress = '';
      this.returnAddress = '';
      this.outboundSeats = this.availableSeats;
      this.returnSeats = this.availableSeats;
      this.returnStart = moment(get(this, 'end'), DATE_FORMAT.dateTime).subtract(15, 'minutes');
    },
    async confirmAddCarpooling() {
      this.isLoading = true;

      const carpoolingParams = getCarpoolingCreationParams({
        outBoundEnabled: this.trips.outBound.enabled,
        returnEnabled: this.trips.return.enabled,
        outboundData: pick(this, ['outboundAddress', 'outboundSeats']),
        returnData: pick(this, ['returnStart', 'returnSeats', 'returnAddress']),
        booking: this.booking,
      });

      try {
        await this.createCarpooling(carpoolingParams);
        this.modal = {
          ...genericSuccessArgs(this.$t),
          title: this.$t('MyBookings.AddCarpoolingModal.FeedbackModal.success.title'),
          description: this.$t('MyBookings.AddCarpoolingModal.FeedbackModal.success.description'),
          primaryCallToAction: () => {
            this.modal = { ...this.modal, isOpen: false };
            this.$emit('on:close', { isEdited: true });
          },
          primaryCallToActionText: this.$t('buttons.got_it'),
          isOpen: true,
        };
      } catch (error) {
        const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        logger.message(`There was a problem adding carpooling to the booking: ${error.message}`, LOG_TYPE.error);

        this.modal = {
          ...genericErrorArgs(this.$t),
          description: errorMessage,
          primaryCallToAction: () => { this.modal = { ...this.modal, isOpen: false }; },
          isOpen: true,
        };
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
