import moment from 'moment';
import get from 'lodash/get';
import find from 'lodash/find';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import map from 'lodash/map';

import {
  canUpgradeInsurance, getAdditionalDrivers, getPassengers, hasInsuranceUpsell, isIntervention, isPreBooking,
} from '@/helpers/booking/bookingHelpers';
import { isDedicatedFleet } from '@/helpers/booking/fleetHelpers';

const BOOKING_FIELDS_REQUIRED = [
  'vehicle',
  'comment',
  'user',
  'is_non_connected',
  'one_way_allowed',
  'cost_allocations',
  'company_cost_allocations',
  'free_seats',
  'is_carpooling',
  'start',
  'end',
  'cs_booking_use_case',
  'uuid',
  'fleet',
  'usage_status_key',
  'vehicle_category',
  'destination_location',
  'insurance',
  'tags',
];

/**
 * isAdditionalDriverEnabled
 * Returns if additionalDriver is enabled for that booking
 *
 * @param   {Object}    booking  booking
 * @param   {Boolean}   isInterventionType  determine if booking isInterventionType
 * @param   {Object}    csOperator  object containing cs operator configuration
 * @return  {Boolean}
 */
export const isAdditionalDriverEnabled = ({ booking, isInterventionType, csOperator }) => {
  const fleetBookingUseCase = get(booking, 'cs_booking_use_case.fleet');
  const isAdditionalDriverAllowed = get(csOperator, 'configuration.allow_additional_driver');

  return !isInterventionType && isDedicatedFleet(fleetBookingUseCase) && Boolean(isAdditionalDriverAllowed);
};

/**
 * isAdditionalPassengersEnabled
 * Returns if additionalPassengers is enabled for that booking
 *
 * @param   {Object}    booking  booking
 * @param   {Boolean}   isInterventionType  determine if booking isInterventionType
 * @param   {Object}    csOperator  object containing cs operator configuration
 * @return  {Boolean}
 */
export const isAdditionalPassengersEnabled = ({ isInterventionType, csOperator, isBusinessProfileActive }) => {
  const allowPassengers = get(csOperator, 'configuration.allow_passengers');

  return !isInterventionType && Boolean(allowPassengers) && isBusinessProfileActive;
};

/**
 * composeBookingData
 * Compose booking data and add cs operator to the booking object
 *
 * @param   {Object}   bookings  bookings
 * @param   {Array} csOperators  array of cs operators configs
 * @param   {Boolean} userHasFullCoveredInsurance
 * @param   {Boolean} isBusinessProfileActive
 * @return  {Object}  returns bookings with enriched data to use on My Bookings
 */
export const composeBookingData = ({
  bookings,
  csOperators = [],
  userHasFullCoveredInsurance = false,
  isBusinessProfileActive = false,
}) => map(bookings, booking => {
  const bookingType = get(booking, 'cs_booking_use_case.booking_type');
  const isInterventionType = isIntervention(bookingType);
  const csOperator = find(csOperators, { uuid: get(booking, 'cs_operator_uuid') });

  return {
    ...pick(booking, BOOKING_FIELDS_REQUIRED),
    csOperator,
    isInsuranceUpgradeAvailaible: canUpgradeInsurance(booking, userHasFullCoveredInsurance),
    hasInsuranceUpsell: hasInsuranceUpsell({ booking }),
    passengers: getPassengers({ booking }),
    additionalDrivers: getAdditionalDrivers({ booking }),
    location: {
      ...booking.location,
      uuid: `location_${booking.uuid}`,
      original_uuid: get(booking, 'location.uuid'),
      vehicle: has(booking, 'vehicle_category') && isEmpty(get(booking, 'location.vehicles'))
        ? booking.vehicle_category
        : booking.vehicle,
    },
    bookingPeriod: {
      start: moment(booking.start),
      end: moment(booking.end),
    },
    destinationLocation: get(booking, 'destination_location'),
    bookingUuid: booking.uuid,
    isIntervention: isIntervention(bookingType),
    isAdditionalDriverEnabled: isAdditionalDriverEnabled({ booking, isInterventionType, csOperator }),
    isAdditionalPassengersEnabled: isAdditionalPassengersEnabled({ booking, isInterventionType, csOperator, isBusinessProfileActive }),
    isBookingRequest: isPreBooking(booking),
  };
});
