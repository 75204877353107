<template>
  <section class="w-100 px-5 mt-5">
    <h2 class="mb-3">
      {{ $t('new_booking.tab.active_bookings.history_bookings') }}
    </h2>
    <ui-loader v-if="isLoading" />
    <MuiTable
      v-if="size(historyBookings)"
      :content-cells="pastBookingsTableConfig"
      :data-set="historyBookings"
      :no-data-label="FALLBACK_MESSAGE.dash"
      :is-loading="isLoading"
      data-test-id="past-booking-table"
      class="mx-3"
    />

    <EmptyStateComponent
      v-else-if="!isLoading"
      has-background
      :src="emptyStateImage"
      :title="$t('new_booking.booking_list.empty_list.title')"
      :action="{
        to: { name: routes.home },
        text: $t('empty_list.link'),
      }"
    />

    <footer
      v-if="size(historyBookings)"
      class="py-4 d-flex justify-content-end mr-4"
    >
      <ui-paginate
        :total="get(historyBookingsPagination, 'total_pages', 1)"
        :page="activePage"
        :size="SIZES.small"
        boundaries
        @first="() => activePage = 1"
        @previous="() => activePage--"
        @next="() => activePage++"
        @last="() => activePage = get(historyBookingsPagination, 'total_pages', 1)"
      />
    </footer>
  </section>
</template>
<script>
import size from 'lodash/size';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
// Libraries
import { MuiTable } from '@emobg/vue-base';

// Utils
import { errorNotification } from '@/handlers/errorHandler.const';
import { BOOKING_TYPES } from '@/constants/bookingTypes';

// Mixins
import {
  fetchHistoryBookings,
  getMyHistoryBookings,
  getMyHistoryBookingsPagination,
  getMyHistoryInterventions,
} from '@/stores/Booking/BookingMapper';

import BookingRoutesNames from '@Bookings/router/routes-names';

// Components
import { COMMON_TABLE_CONFIG } from '@/components/MyBookings/constants/tableConfig';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import { emptyActiveBookings } from '@/utils/publicImages';

export default {
  name: 'MyPastBookingsTab',

  components: {
    EmptyStateComponent,
    MuiTable,
  },

  props: {
    bookingTypes: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const { notifyError } = useNotifications();
    return {
      notifyError,
    };
  },
  data() {
    return {
      activePage: 1,
      isLoading: false,
    };
  },

  computed: {
    getMyHistoryBookings,
    getMyHistoryInterventions,
    getMyHistoryBookingsPagination,

    hasOnlyInterventionType() {
      return isEqual(this.bookingTypes, [BOOKING_TYPES.intervention]);
    },
    historyBookings() {
      return this.hasOnlyInterventionType
        ? get(this, 'getMyHistoryInterventions')
        : get(this, 'getMyHistoryBookings.data.data');
    },
    historyBookingsPagination() {
      return get(this, 'getMyHistoryBookingsPagination');
    },
  },

  watch: {
    activePage() {
      this.loadHistoryBookings();
    },
  },

  created() {
    this.loadHistoryBookings();
    this.pastBookingsTableConfig = COMMON_TABLE_CONFIG || [];
    this.routes = BookingRoutesNames;
    this.emptyStateImage = emptyActiveBookings;
  },

  methods: {
    get,
    size,
    fetchHistoryBookings,

    async loadHistoryBookings() {
      try {
        this.isLoading = true;
        const payload = {
          page: this.activePage,
          booking_types: this.bookingTypes,
        };

        await this.fetchHistoryBookings({ payload });
      } catch (error) {
        this.notifyError(errorNotification(error));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
