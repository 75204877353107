import get from 'lodash/get';
import some from 'lodash/some';
import { isLowBattery } from '@/helpers/booking/evHelpers';

export const showLowBatteryNotification = showNotification => bookings => some(bookings, booking => {
  const electricDetails = get(booking, 'vehicle.electric_details', null);
  const bookingStart = get(booking, 'start');
  const operator = get(booking, 'csOperator');

  const isNotificationVisible = isLowBattery(bookingStart, operator, electricDetails);

  if (isNotificationVisible) {
    showNotification();
    return true;
  }

  return false;
});
