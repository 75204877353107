import moment from 'moment';
import map from 'lodash/map';
import lowerCase from 'lodash/lowerCase';
import startCase from 'lodash/startCase';
import zip from 'lodash/zip';
import first from 'lodash/first';
import last from 'lodash/last';
import times from 'lodash/times';
import filter from 'lodash/filter';
import gte from 'lodash/gte';
import lte from 'lodash/lte';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import flatMap from 'lodash/flatMap';
import invoke from 'lodash/invoke';
import isNil from 'lodash/isNil';
import some from 'lodash/some';
import isUndefined from 'lodash/isUndefined';
import get from 'lodash/get';
import divide from 'lodash/divide';
import round from 'lodash/round';
import floor from 'lodash/floor';
import take from 'lodash/take';
import takeWhile from 'lodash/takeWhile';

import { DATE_FORMAT } from '@emobg/web-utils';

import { DATE_LOCALES, DATE_UNITS, MOMENT_METHODS } from '@/constants/dates';
import { RECURRENCE_OPTIONS } from '../constants/recurrenceOptions';

const RECURRENCE_OPTIONS_MOMENT_METHOD = {
  [RECURRENCE_OPTIONS.weekly]: MOMENT_METHODS.week,
  [RECURRENCE_OPTIONS.monthly]: MOMENT_METHODS.month,
  [RECURRENCE_OPTIONS.weekday]: MOMENT_METHODS.days,
  [RECURRENCE_OPTIONS.custom]: MOMENT_METHODS.week,
};

const RECURRENCE_OPTIONS_STEPPER_MOMENT_METHOD = {
  [RECURRENCE_OPTIONS.weekly]: MOMENT_METHODS.week,
  [RECURRENCE_OPTIONS.monthly]: MOMENT_METHODS.month,
  [RECURRENCE_OPTIONS.weekday]: MOMENT_METHODS.weekday,
  [RECURRENCE_OPTIONS.custom]: MOMENT_METHODS.week,
};

/**
 * @function recurrenceOptionToMomentMethod
 * @desc It maps a recurrence option string into the proper moment method name string
 *
 * @param {String} recurrenceOption
 * @example <caption>Example usage of recurrenceOptionToMomentMethod.</caption>
 * // returns "week"
 * recurrenceOptionToMomentMethod('weekly')
 * @returns {String}
 */
export const recurrenceOptionToMomentMethod = recurrenceOption => {
  const momentMethod = get(RECURRENCE_OPTIONS_MOMENT_METHOD, recurrenceOption);
  if (isUndefined(momentMethod)) {
    throw new Error('Wrong recurrenceOption name');
  }
  return momentMethod;
};

/**
 * @function stepperMethodByRecurrenceOption
 * @desc It maps a recurrence option string into the proper moment stepper method name string
 *
 * @param {String} recurrenceOption
 * @example <caption>Example usage of stepperMethodByRecurrenceOption.</caption>
 * // returns "month"
 * stepperMethodByRecurrenceOption('monthly')
 * @returns {String}
 */
export const stepperMethodByRecurrenceOption = recurrenceOption => {
  const momentMethod = get(RECURRENCE_OPTIONS_STEPPER_MOMENT_METHOD, recurrenceOption);
  if (isUndefined(momentMethod)) {
    throw new Error('Wrong recurrenceOption name');
  }
  return momentMethod;
};

/**
 * @function generateWeekDaysOptions
 * @desc It returns a list of three values object of the weekdays according to the language passed as argument
 * (default: english). Object fields are the 'label' as name capitalized, 'value' as the weekday position and
 * 'key' as name lowercase
 *
 * @param {String} language
 * @example <caption>Example usage of generateWeekDaysOptions.</caption>
 * // returns "[
 *  { key: 'sunday', label: 'Domingo', value: 0 },
    { key: 'monday', label: 'Lunes', value: 1 },
    { key: 'tuesday', label: 'Martes', value: 2 },
    { key: 'wednesday', label: 'Miercoles', value: 3 },
    { key: 'thursday', label: 'Jueves', value: 4 },
    { key: 'friday', label: 'Viernes', value: 5 },
    { key: 'saturday', label: 'Sabado', value: 6 }
   ]"
 * generateWeekDaysOptions('es')
 * @returns {Array}
 */
export const generateWeekDaysOptions = (language = DATE_LOCALES.en) => {
  moment.locale(DATE_LOCALES.en);
  const weekdaysKeys = map(moment.weekdays(), lowerCase);
  moment.locale(language);
  const weekdaysTranslations = map(moment.weekdays(), startCase);
  const zippedWeekdays = zip(weekdaysTranslations, weekdaysKeys);
  return map(
    zippedWeekdays,
    (weekdayPair, index) => ({ label: first(weekdayPair), value: index, key: last(weekdayPair) }),
  );
};

/**
 * @function calculateRecurrencePeriods
 * @desc It returns a list of three values object of the weekdays according to the language passed as argument
 * (default: english). Object fields are the 'label' as name capitalized, 'value' as the weekday position and
 * 'key' as name lowercase
 *
 * @param {String} recurrenceOption
 * @param {Number} recurrenceCardinal
 * @param {Moment} startBooking
 * @param {Moment} endBooking
 * @param {Moment} limitDate
 * @param {Array<Number>} [customOptions=[]]
 * @example <caption>Example usage of calculateRecurrencePeriods.</caption>
 * // returns "[
            { start: '2021-09-01 10:00:00', end: '2021-09-01 11:00:00' },
            { start: '2021-10-01 10:00:00', end: '2021-10-01 11:00:00' },
            { start: '2021-11-01 10:00:00', end: '2021-11-01 11:00:00' }]"
 * calculateRecurrencePeriods({
   recurrenceOption: 'weekly', recurrenceCardinal: 3, startBooking: moment.utc('2021-09-01 10:00:00'), endBooking: moment.utc('2021-09-01 11:00:00')
  })
 * @returns {Array<Object>}
 */
export const calculateRecurrencePeriods = ({
  recurrenceOption, recurrenceCardinal, startBooking, endBooking, limitDate, customOptions = [],
}) => {
  const isMissingArgument = some([recurrenceOption, recurrenceCardinal, startBooking, endBooking], isNil);
  if (isMissingArgument) {
    throw new Error('[Error] Missing mandatory arguments: recurrenceOption, recurrenceCardinal, startBooking and endBooking are mandatory arguments');
  }

  const isLimitDateValid = !isNil(limitDate) && moment.isMoment(limitDate) && limitDate.isValid();

  // Get moment method to 'walk' through the dates (every week, every month..) according to recurrence option chosen
  const dateWalkerMethod = recurrenceOptionToMomentMethod(recurrenceOption);
  const dateStepperUnit = stepperMethodByRecurrenceOption(recurrenceOption);

  // First booking dates
  const startingMoment = invoke(moment(startBooking), dateStepperUnit);
  const endingMoment = invoke(moment(startBooking), dateStepperUnit);

  const iteratorArray = times(recurrenceCardinal);

  // Preset recurrence options
  if (isEmpty(customOptions)) {
    const bookingsByCardinal = map(iteratorArray, recurrenceTimeIndex => {
      const start = invoke(moment(startBooking), dateWalkerMethod, (startingMoment + recurrenceTimeIndex)).format(DATE_FORMAT.filter);
      const end = invoke(moment(endBooking), dateWalkerMethod, (endingMoment + recurrenceTimeIndex)).format(DATE_FORMAT.filter);

      return { start, end };
    });

    return isLimitDateValid
      ? takeWhile(bookingsByCardinal, period => moment(period.start).isSameOrBefore(limitDate, DATE_UNITS.day))
      : bookingsByCardinal;
  }

  // Custom recurrence option flow
  const startBookingWeek = moment(startBooking).week();
  const endBookingWeek = moment(endBooking).week();
  const startWeekday = moment(startBooking).isoWeekday();
  const endWeekday = moment(endBooking).isoWeekday();

  // Week of the first start booking. The day of the start booking might be at the start, middle or end of the week
  const firstWeekApplicableDays = filter(customOptions, optionWeekDay => gte(optionWeekDay, startWeekday));
  // Week of the last end booking. The day of the end booking might be at the start, middle or end of the week
  const lastWeekApplicableDays = filter(customOptions, optionWeekDay => lte(optionWeekDay, endWeekday));

  const maxBookingsByCardinal = take(flatMap(map(iteratorArray, recurrenceTimeIndex => {
    // Range middle weeks
    let weekDaysToApply = customOptions;
    // First week
    if (recurrenceTimeIndex === 0) {
      weekDaysToApply = firstWeekApplicableDays;
    } else if (recurrenceTimeIndex + 1 === recurrenceCardinal) {
      // Last week
      weekDaysToApply = lastWeekApplicableDays;
    }
    return map(weekDaysToApply, weekDay => ({
      start: moment(startBooking).week(startBookingWeek + recurrenceTimeIndex).isoWeekday(weekDay).format(DATE_FORMAT.filter),
      end: moment(endBooking).week(endBookingWeek + recurrenceTimeIndex).isoWeekday(weekDay).format(DATE_FORMAT.filter),
    }));
  })), recurrenceCardinal);

  return isLimitDateValid
    ? takeWhile(maxBookingsByCardinal, period => moment(period.start).isSameOrBefore(limitDate, DATE_UNITS.day))
    : maxBookingsByCardinal;
};

/**
 * @function calculateCustomRecurrenceEnd
 * @desc It returns the last date possible to choose for the recurrence options selected for "custom"
 *  Those options are an array representing the days of the week
 *  The boundaries of this date is calculate using the weekdays chosen and the "recurrenceLimit"
 *  The "recurrenceLimit" represents the maximum number of bookings allowed in a recurrence by the Operator
 *
 * @param {Moment} start
 * @param {Number} [recurrenceLimit=0]
 * @param {Array<Number>} [customOptions=[]]
 * @example <caption>Example usage of calculateCustomRecurrenceEnd.</caption>
 * // returns "Moment('2022-09-01 10:00:00')"
 * calculateCustomRecurrenceEnd({
   start: moment.utc('2022-09-01 10:00:00'), recurrenceLimit: 20, customOptions: [1, 3, 5]
  })
 * @returns {Moment}
 */
export const calculateCustomRecurrenceEnd = ({ start, recurrenceLimit = 0, customOptions = [] }) => {
  const isMissingArgument = some([start], isNil);
  if (isMissingArgument) {
    throw new Error('[Error] Missing mandatory arguments: start');
  }

  if (isEmpty(customOptions) || recurrenceLimit <= 0) {
    return start;
  }

  const startDate = moment(start);
  const bookingStartWeekday = startDate.isoWeekday();
  const numberDaysPerWeek = size(customOptions);
  const firstWeekDays = size(filter(customOptions, customOption => gte(customOption, bookingStartWeekday)));

  const remainingRecurrenceBookings = recurrenceLimit - firstWeekDays;
  const weeksRange = numberDaysPerWeek > 0
    ? divide(remainingRecurrenceBookings, numberDaysPerWeek)
    : 0;

  const weeksRangeFloor = floor(weeksRange);
  const lastWeek = moment(startDate).add(weeksRangeFloor, DATE_UNITS.weeks);
  const decimals = round(weeksRange % 1.0, 2);

  if (decimals !== 0) {
    const fullWeeksRecurrenceBookings = numberDaysPerWeek * weeksRangeFloor;
    const lastWeekRemainingBookings = remainingRecurrenceBookings - fullWeeksRecurrenceBookings;

    const lastWeekRemainingWeekdays = take(customOptions, lastWeekRemainingBookings);
    const lastDayWeekday = last(lastWeekRemainingWeekdays);

    return moment()
      .year(lastWeek.year())
      .isoWeek(lastWeek.isoWeek() + 1)
      .isoWeekday(lastDayWeekday)
      .startOf(DATE_UNITS.day);
  }

  return moment(lastWeek).isoWeekday(last(customOptions)).startOf(DATE_UNITS.day);
};

/**
 * @function calculateCustomRecurrenceCardinal
 * @desc It returns the number of bookings it must be created for the recurrence options and date passed
 *  "start" represents when the first booking starts
 *  "selectLimitDate" represents when till when it must be created the recurrent bookings (it may not include that day)
 *  "customOptions" represents the weekdays to apply the recurrent bookings
 *
 *
 * @param {Moment} start
 * @param {Moment} selectedLimitDate
 * @param {Array<Number>} [customOptions=[]]
 * @example <caption>Example usage of calculateCustomRecurrenceCardinal.</caption>
 * // returns "12"
 * calculateCustomRecurrenceEnd({
   start: moment.utc('2022-09-01 10:00:00'), recurrenceLimit: moment.utc('2022-19-01 10:00:00'), customOptions: [1, 3, 5]
  })
 * @returns {Moment}
 */
export const calculateCustomRecurrenceCardinal = ({ start, selectedLimitDate, customOptions = [] }) => {
  const isMissingArgument = some([start, selectedLimitDate], isNil);
  if (isMissingArgument) {
    throw new Error('[Error] Missing mandatory arguments: start, selectedLimitDate');
  }

  if (isEmpty(customOptions)) {
    return 0;
  }

  const bookingStartWeekday = start.isoWeekday();
  const limitDateWeekday = selectedLimitDate.isoWeekday();

  const numberDaysPerWeek = size(customOptions);

  const limitDateIsOnSameWeek = start.isoWeek() === selectedLimitDate.isoWeek();

  const firstWeekDays = size(filter(customOptions, customOption => {
    const lowerThanLimitDate = limitDateIsOnSameWeek
      ? lte(customOption, limitDateWeekday)
      : true;
    return gte(customOption, bookingStartWeekday) && lowerThanLimitDate;
  }));

  const distanceDatesWeeks = isNil(selectedLimitDate)
    ? 0
    : selectedLimitDate.isoWeek() - start.isoWeek();

  const fullWeeks = distanceDatesWeeks > 1
    ? distanceDatesWeeks - 1
    : 0;

  const fullWeeksDays = floor(fullWeeks) * numberDaysPerWeek;

  const lastWeekDays = limitDateIsOnSameWeek
    ? 0
    : size(filter(customOptions, customOption => lte(customOption, limitDateWeekday)));

  const calculation = firstWeekDays + fullWeeksDays + lastWeekDays;

  return floor(calculation);
};
