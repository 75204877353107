<script>
import get from 'lodash/get';
import filter from 'lodash/filter';
import size from 'lodash/size';
import { compactArray, FALLBACK_MESSAGE } from '@emobg/web-utils';
import { bookingDuration } from '@/helpers/booking/bookingHelpers';
import {
  getMaximumPrice,
  getMinimumPrice,
  hasAllRangesSamePrice,
  hasUnlimitedMileage,
} from '@/components/VehicleCard/components/TariffInformation/helpers.const';

export default {
  name: 'PaymentSummaryComponent',
  props: {
    isElectric: {
      type: Boolean,
      default: false,
    },
    showPrices: {
      type: Boolean,
      default: true,
    },
    totalMileageIncluded: {
      type: Boolean,
      default: false,
    },
    start: {
      type: String,
      default: '',
    },
    end: {
      type: String,
      default: '',
    },
    prices: {
      type: Object,
      default: () => ({}),
    },
    insurance: {
      type: Object,
      default: null,
    },
    isOutsideWorkingHours: {
      type: Boolean,
      default: false,
    },
    tariff: {
      type: Object,
      required: true,
    },
  },

  computed: {
    hasUnlimitedMileage() {
      return hasUnlimitedMileage(this.tariff);
    },
    freeMileageText() {
      const isMigrated = get(this, 'tariff.is_migrated', false);

      return isMigrated
        ? this.$t('tariff.payment_summary.migrated_free_mileage', { mileage: get(this, 'tariff.free_mileage') })
        : get(this, 'tariff.free_mileage');
    },
    extraMileageText() {
      const minimumPrice = getMinimumPrice(this.tariff);
      const maximumPrice = getMaximumPrice(this.tariff);
      return hasAllRangesSamePrice(this.tariff)
        ? this.$t('tariff.payment_summary.extra_mileage_same_price_value', { price: minimumPrice })
        : this.$t('tariff.payment_summary.extra_mileage_different_price_value', { minimumPrice, maximumPrice });
    },
    hasPricedRanges() {
      const ranges = get(this, 'tariff.mileage_price_ranges', []);
      return size(filter(ranges, ({ price_raw: price }) => price !== 0));
    },
    total() {
      return this.data.insurance
        ? this.data.insurance.total
        : this.data.prices.total;
    },

    data() {
      const insurance = this.insurance
        ? {
          name: this.insurance.name,
          total: this.insurance.total || FALLBACK_MESSAGE.dash,
        }
        : null;

      const prices = this.prices || {};

      const pricing = {
        mileageIncluded: prices.mileage_included,
        timeSubtotal: prices.time_subtotal || FALLBACK_MESSAGE.dash,
        totalMileageIncluded: prices.total_mileage_included || '',
        mileageSubtotal: prices.mileage_subtotal || FALLBACK_MESSAGE.dash,
        promotionsSubtotal: prices.promotions_subtotal,
        freeMileageText: prices.free_mileage_text,
        mileagePrice: prices.mileage_price || FALLBACK_MESSAGE.dash,
        total: prices.total || FALLBACK_MESSAGE.dash,
      };

      return {
        insurance,
        prices: pricing,
      };
    },
  },

  created() {
    try {
      const { days, hours, minutes } = bookingDuration(this.start, this.end);

      this.bookingDuration = compactArray([
        days ? this.$t('common.format_days', { count: days }, days) : null,
        hours ? this.$t('common.format_hours', { count: hours }, hours) : null,
        minutes ? this.$t('common.format_minutes', { count: minutes }, minutes) : null,
      ]).join(' ');
    } catch (error) {
      this.bookingDuration = FALLBACK_MESSAGE.dash;
    }
  },
  methods: {
    get,
  },
};
</script>

<template>
  <div>
    <div
      class="
        pb-2 mb-3
        emobg-border-bottom-1 emobg-border-color-ground
        emobg-font-small emobg-font-line-height-large emobg-font-weight-bold emobg-color-ink
      "
    >
      {{ $t('carsharing_personal.title_3') }}
    </div>

    <div class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink">
      <span data-test-id="payment-summary_booking-duration">
        {{ `${$t('payment_summary.booking_time')} ${bookingDuration}` }}
      </span>
      <span
        v-if="showPrices"
        data-test-id="payment-summary_time-subtotal"
      >
        {{ data.prices.timeSubtotal }}
      </span>
    </div>

    <template v-if="showPrices">
      <div
        v-if="hasUnlimitedMileage"
        data-test-id="unlimited_mileage-included"
        class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink"
      >
        <span>{{ $t('tariff.payment_summary.unlimited_label') }}</span>
        <ui-badge>{{ $t('common.included') }}</ui-badge>
      </div>
      <div
        v-else-if="get(tariff, 'free_mileage')"
        data-test-id="free_mileage-included"
        class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink"
      >
        <span>{{ freeMileageText }}</span>
        <ui-badge>{{ $t('common.included') }}</ui-badge>
      </div>
      <div
        v-if="!isElectric"
        data-test-id="payment-summary_fuel-included"
        class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink"
      >
        <span>{{ $t('common.fuel') }}</span>
        <ui-badge>{{ $t('common.included') }}</ui-badge>
      </div>
    </template>

    <div
      v-if="insurance"
      data-test-id="payment-summary_insurance"
      class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink-light"
    >
      <span>{{ insurance.name }}</span>
      <span
        v-if="showPrices"
        data-test-id="payment-summary_insurance-price"
      >
        {{ insurance.price }}
      </span>
    </div>

    <div
      v-if="totalMileageIncluded"
      data-test-id="payment-summary_total-mileage-included"
      class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink-light"
    >
      <span>
        {{ $t('payment_summary.min_mileage') }} {{ data.prices.totalMileageIncluded }}
      </span>
      <span
        v-if="showPrices"
        data-test-id="payment-summary_total-mileage-included-price"
      >
        {{ data.prices.mileageSubtotal }}
      </span>
    </div>

    <template v-if="showPrices">
      <div
        v-if="data.prices.promotionsSubtotal"
        data-test-id="payment-summary_promotion"
        class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink-light"
      >
        <span>{{ $t('payment_summary.discount') }}</span>
        <span data-test-id="payment-summary_promotion-price">
          {{ `-${data.prices.promotionsSubtotal}` }}
        </span>
      </div>

      <hr class="emobg-border-color-ground">

      <div
        class="
        d-flex align-items-center justify-content-between mb-2
        emobg-font-medium emobg-font-weight-bold emobg-color-ink
      "
      >
        <span>{{ $t('payment_summary.total') }}</span>
        <span data-test-id="payment-summary_total">
          {{ total }}
        </span>
      </div>

      <div
        v-if="hasPricedRanges"
        data-test-id="extra-mileage_text"
        class="d-flex align-items-center justify-content-between mb-2 emobg-color-ink-light"
      >
        <span>{{ $t('tariff.payment_summary.extra_mileage_label') }}</span>
        <span data-test-id="extra-mileage_value">
          {{ extraMileageText }}
        </span>
      </div>
    </template>
    <div
      v-if="isOutsideWorkingHours"
      data-test-id="payment-summary_outside-working-hours"
      class="d-flex align-items-center mb-2 emobg-color-ink-light"
    >
      * {{ $t('payment_summary.outside_working_hours') }}
    </div>
  </div>
</template>
