import get from 'lodash/get';
import { FALLBACK_MESSAGE, formatDate } from '@emobg/web-utils';

import { isOneWay } from '@/helpers/booking/bookingHelpers';
import { useTranslations } from '@/composable/App/Translations';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

import ActionButton from '@/components/MyBookings/tables/templates/ActionButton';
import BadgeComponent from '@/components/BadgeComponent/BadgeComponent';

const { $t } = useTranslations();

const STATE_TYPES = {
  active: 'success',
  cancelled: 'danger',
  finished: 'success',
  notUsed: 'ground',
  inactive: 'danger',
};
const commonTranslationPrefix = 'booking_history';

export const COMMON_TABLE_CONFIG = [
  {
    title: $t(`${commonTranslationPrefix}.table.start_time`),
    width: 200,
    property: 'start',
    tooltip: true,
  },
  {
    title: $t(`${commonTranslationPrefix}.table.end_time`),
    width: 200,
    property: 'end',
  },
  {
    title: $t(`${commonTranslationPrefix}.table.car`),
    width: 200,
    property: 'vehicle',
    transformValue: vehicle => `${vehicle.brand} ${vehicle.model}`,
  },
  {
    title: $t(`${commonTranslationPrefix}.table.parking`),
    width: 200,
    property: 'location.name',
  },
  {
    title: $t(`${commonTranslationPrefix}.table.return_station`),
    width: 200,
    testId: 'return_station',
    transformValue: booking => (isOneWay(booking) ? get(booking, 'location.name') : null),
  },
  {
    title: $t(`${commonTranslationPrefix}.table.status`),
    width: 200,
    component: BadgeComponent,
    property: 'status_key',
    props: booking => ({
      text: $t(`booking_status.${booking.status_key}`),
      color: STATE_TYPES[booking.status_key],
    })
    ,
  },
  {
    title: $t(`${commonTranslationPrefix}.reference`),
    property: 'uuid',
    folded: true,
    width: 400,
  },
  {
    title: $t(`${commonTranslationPrefix}.fleet`),
    property: 'cs_booking_use_case.fleet',
    transformValue: fleet => $t(`constants.fleet_type.${fleet}`, FALLBACK_MESSAGE.dash),
    folded: true,
    width: 300,
  },
  {
    title: $t('business_profile.booking_overview.bubble.category'),
    property: 'vehicle.category',
    folded: true,
    width: 300,
  },
  {
    title: $t('business_profile.fleet.license_plate'),
    property: 'vehicle.license_plate',
    folded: true,
    width: 300,
  },
  {
    title: $t(`${commonTranslationPrefix}.user_name`),
    property: 'user',
    masked: true,
    transformValue: user => `${user.first_name} ${user.last_name}`,
    folded: true,
    width: 300,
  },
  {
    title: $t(`${commonTranslationPrefix}.real_start_time`),
    property: 'real_start',
    transformValue: date => formatDate(date, DATETIME_FORMATS.longPrettyDatetime),
    folded: true,
    width: 250,
  },
  {
    title: $t(`${commonTranslationPrefix}.real_end_time`),
    property: 'real_end',
    transformValue: date => formatDate(date, DATETIME_FORMATS.longPrettyDatetime),
    folded: true,
    width: 250,
  },
  {
    title: $t(`${commonTranslationPrefix}.duration`),
    property: 'total_time',
    folded: true,
    width: 250,
  },
  {
    title: $t(`${commonTranslationPrefix}.mileage`),
    property: 'mileage',
    folded: true,
    width: 250,
  },
];

export const LOGBOOK_TABLE_CONFIG = actions => ([
  ...COMMON_TABLE_CONFIG,
  {
    title: $t(`${commonTranslationPrefix}.table.logbook`),
    width: 200,
    component: ActionButton,
    property: 'logbook_completed',
    props: booking => ({
      text: $t(`${commonTranslationPrefix}.logbook_${booking.logbook_completed ? 'logged' : 'now'}`),
      disabled: booking.logbook_completed,
      onClick: () => actions.logbook(booking),
      dataTestId: 'fill_logbook-button',
    })
    ,
  },
]);
