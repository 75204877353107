<script>
import LocationHeaderBaseComponent from './LocationHeaderBaseComponent';

export default {
  name: 'LocationHeaderMyBookingComponent',

  components: {
    LocationHeaderBaseComponent,
  },

  props: {
    locations: {
      type: Array,
      default: null,
    },
    dates: {
      type: Array,
      required: true,
    },
  },

};
</script>

<template>
  <div class="LocationHeaderBookComponent">
    <LocationHeaderBaseComponent
      :locations="locations"
      @address-clicked="$emit('address-clicked', $event)"
    >
      <template #extraInfo>
        <div
          v-for="(date, index) in dates"
          :key="index"
          class="d-flex align-items-center"
        >
          <i
            :data-test-id="`location-header-my-bookings-${date.testId}-icon`"
            class="invisible icons-medium-square mr-2"
          />
          <strong
            :data-test-id="`location-header-my-bookings-${date.testId}-label`"
            class="emobg-font-weight-bold mr-1 emobg-font-line-height-large"
          >
            {{ date.label }}:
          </strong>
          <span
            :data-test-id="`location-header-my-bookings-${date.testId}-date`"
            class="mr-2 emobg-color-ink-light"
          >
            {{ date.date }}
          </span>
        </div>
      </template>
    </LocationHeaderBaseComponent>
  </div>
</template>
