<template>
  <ui-modal
    :open="value"
    :size="SIZES.small"
    :header="$t('BookingView.AvailabilitySuggestionsModal.title')"
    data-test-id="availability_suggestions-modal"
  >
    <div slot="body">
      <ui-validate
        id="recurringBookingsModalForm"
        @status="({ detail }) => formStatus = detail"
      >
        <div class="d-flex flex-column">
          <p class="emobg-body-medium emobg-color-ink mb-4">
            {{ $t('BookingView.AvailabilitySuggestionsModal.subtitle') }}
          </p>
          <ui-select
            :value="selectedSuggestionOption"
            :label="$t('BookingView.AvailabilitySuggestionsModal.suggestionOptionSelect.label')"
            :placeholder="$t('BookingView.AvailabilitySuggestionsModal.suggestionOptionSelect.placeholder')"
            :options.prop="suggestionsOptions"
            :size="SIZES.large"
            :disabled="isLoading"
            name="suggestionOptionSelect"
            class="mb-4"
            data-test-id="suggestion_option-select"
            required
            @selectoption="({ detail }) => onChangeSuggestionOption(detail)"
          />
          <ui-loader v-if="isLoading" />
          <ui-radio
            v-for="(datesSuggestion, index) in availableSuggestions"
            v-else
            :key="index"
            v-validate="{ isRequired: true }"
            :value="selectedSuggestion"
            :option="index"
            :data-test-id="`suggestion_option-${index}`"
            name="suggestionsRadioButtons"
            @changevalue="({ detail }) => selectedSuggestion = detail"
          >
            <div
              slot="label"
              class="Ui-Radio__caption"
            >
              <div
                v-if="selectedSuggestionOption === availabiltySuggestionsOptions.none"
                class="d-flex flex-column"
              >
                <div class="emobg-font-body-medium">
                  {{ $t('common.booking.start') }}: {{ formatDate(datesSuggestion.start, dateFormats.timeOfDay) }}
                  {{ $t('common.booking.end') }}: {{ formatDate(datesSuggestion.end, dateFormats.timeOfDay) }}
                </div>
                <div class="emobg-font-body-small emobg-color-ink-light">
                  <span>{{ formatDate(datesSuggestion.start, datetimeFormats.shortPrettyDateMonth) }}</span>
                  <span v-if="!moment(datesSuggestion.start).isSame(moment(datesSuggestion.end), dateUnits.days)">
                    {{ $t('BookingView.AvailabilitySuggestionsModal.suggestionsRadioButtons.suggestionRow.none.secondLine',
                          { endDate: moment(datesSuggestion.end).format(datetimeFormats.shortPrettyDateMonth) })
                    }}
                  </span>
                </div>
              </div>
              <div v-else>
                {{ $t(`BookingView.AvailabilitySuggestionsModal.suggestionsRadioButtons.suggestionRow.${selectedSuggestionOption}`,
                      { date: selectedSuggestionOption === availabiltySuggestionsOptions.fixStart
                        ? moment(datesSuggestion.end).format(dateFormats.timeOfDay)
                        : moment(datesSuggestion.start).format(dateFormats.timeOfDay) })
                }}
              </div>
            </div>
          </ui-radio>
          <AlertComponent
            v-if="(!size(availableSuggestions) && hasFetchSuggestions && !isLoading) || errorMessage"
            :type="errorMessage ? alertTypes.danger : alertTypes.warning"
            class="w-100 mb-3"
            data-test-id="availability_suggestions-alert"
          >
            {{ errorMessage || $t('BookingView.AvailabilitySuggestionsModal.alert.noResults') }}
          </AlertComponent>
        </div>
      </ui-validate>
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="isLoading"
        class="mr-2"
        data-test-id="cancel-button"
        @clickbutton="() => {
          trackEvent(segmentEvents.skipSuggestedTime);
          $emit('on:close');
        }"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="isConfirmDisabled"
        :loading="isButtonLoading"
        data-test-id="confirm-button"
        @clickbutton="confirmSuggestion"
      >
        {{ $t('buttons.confirm') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
<script>
import indexOf from 'lodash/indexOf';
import isNil from 'lodash/isNil';
import nth from 'lodash/nth';
import size from 'lodash/size';

import { LOADER, Validate } from '@emobg/vue-base';

import {
  DATE_FORMAT,
  formatDate,
  LOG_TYPE,
  logger,
} from '@emobg/web-utils';
import { external } from '@emobg/web-api-client';
import AlertComponent from '@/components/Alert/AlertComponent';

import { parseApiErrorMessage } from '@/utils/apiHelpers';

import { DATE_UNITS } from '@/constants/dates';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { useSegment } from '@/composable/Segment/segment';
import { SEGMENT_EVENTS } from '@/constants/segment';
import { useTheme } from '@/composable/Theme/useTheme';
import { AVAILABILTY_SUGGESTIONS_OPTIONS, AVAILABILTY_SUGGESTIONS_OPTIONS_SELECT } from './constants/suggestionsOptions.const';

export default {
  name: 'AvailabilitySuggestionsModal',
  components: {
    AlertComponent,
  },
  directives: {
    Validate,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    start: {
      type: String,
      required: true,
    },
    end: {
      type: String,
      required: true,
    },
    categoryUuid: {
      type: String,
      required: true,
    },
    locationUuid: {
      type: String,
      required: true,
    },
    locationCSOperator: {
      type: String,
      required: true,
    },
    bookingType: {
      type: String,
      required: true,
    },
    onConfirmFn: {
      type: Function,
      required: true,
    },
  },
  setup() {
    const { trackSegment } = useSegment();
    const { fetchButtonSpecs } = useTheme();

    return { fetchButtonSpecs, trackSegment };
  },
  data() {
    return {
      formStatus: { isValid: false },
      isLoading: false,
      isButtonLoading: false,
      selectedSuggestionOption: null,
      selectedSuggestion: null,
      availableSuggestions: [],
      hasFetchSuggestions: false,
      errorMessage: null,
    };
  },
  computed: {
    isConfirmDisabled() {
      return this.isLoading || this.isButtonLoading || !this.formStatus.isValid || isNil(this.selectedSuggestion);
    },
  },
  created() {
    this.dateFormats = DATE_FORMAT;
    this.datetimeFormats = DATETIME_FORMATS;
    this.dateUnits = DATE_UNITS;
    this.availabiltySuggestionsOptions = AVAILABILTY_SUGGESTIONS_OPTIONS;
    this.suggestionsOptions = AVAILABILTY_SUGGESTIONS_OPTIONS_SELECT;
    this.alertTypes = ALERT_TYPES;
    this.loaderVariants = LOADER;
    this.segmentEvents = SEGMENT_EVENTS;
  },
  methods: {
    size,
    formatDate,
    trackEvent(name) {
      this.trackSegment({
        name,
        data: {
          locationOperatorUuid: this.locationCSOperator,
          availabilityType: this.selectedSuggestionOption,
        },
      });
    },
    async onChangeSuggestionOption(selectedOption) {
      this.trackEvent(SEGMENT_EVENTS.pickStackLocationAvailabilityType);
      this.errorMessage = null;
      this.selectedSuggestionOption = selectedOption;
      this.hasFetchSuggestions = true;
      this.isLoading = true;
      const { start, end } = this;
      const payload = {
        start,
        end,
        category_uuid: this.categoryUuid,
        location_uuid: this.locationUuid,
        booking_type: this.bookingType,
        time_block: this.selectedSuggestionOption,
      };
      try {
        const { options } = await external.availability.getStackAvailability(payload);
        this.availableSuggestions = options;
        this.selectedSuggestion = indexOf(this.availableSuggestions, 1);
      } catch (error) {
        logger.message(`There was a problem fetching availability suggestions: ${error.message}`, LOG_TYPE.error);
        this.errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
      } finally {
        this.isLoading = false;
      }
    },
    confirmSuggestion() {
      this.isButtonLoading = true;
      this.trackEvent(this.segmentEvents.selectSuggestedTime);
      const selectedSuggestion = nth(this.availableSuggestions, this.selectedSuggestion);
      this.onConfirmFn({ start: selectedSuggestion.start, end: selectedSuggestion.end });
    },
  },
};
</script>
