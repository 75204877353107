<template>
  <ui-modal
    v-bind="$attrs"
    :closable="false"
    :header="title"
    :open="isOpen"
    :size="SIZES.small"
    data-test-id="change_time_slot-modal"
  >
    <div slot="body">
      <ui-alert
        v-if="!isVehicleAvailable"
        data-test-id="modal-warning"
        :color="COLORS.warning"
        class="py-1 mb-2"
      >
        {{ $t('BookingView.ChangeTimeSlotModalComponent.vehicle_not_available') }}
      </ui-alert>
      <p
        data-test-id="message"
        class="emobg-font-default emobg-font-line-height-large mb-2"
        v-html="message"
      />
      <AlertComponent
        :type="ALERT_TYPES.informative"
        class="w-100 mb-1"
      >
        <div>
          <div>
            {{ $t('BookingView.ChangeTimeSlotModalComponent.booking_rules') }}
          </div>
          <div data-test-id="booking_rules_minimum-label">
            {{ $t('BookingView.ChangeTimeSlotModalComponent.minimum_time') }} {{ formattedMinimumDuration }}
          </div>
          <div
            v-if="formattedMaximumDuration"
            data-test-id="booking_rules_maximum-label"
          >
            {{ $t('BookingView.ChangeTimeSlotModalComponent.maximum_time') }} {{ formattedMaximumDuration }}
          </div>
        </div>
      </AlertComponent>
      <BookingRules
        is-vertical
        :datetimepicker-manager="datetimepickerManager"
        @change-datetimepicker-values="onDatetimepickerChange"
      />
    </div>
    <div
      slot="footer"
      class="d-flex align-items-center justify-content-end"
    >
      <ui-button
        v-bind="fetchButtonSpecs({ buttonType: THEME_BUTTON_TYPES.SECONDARY })"
        :disabled="isLoading || isLeaving"
        data-test-id="cancel-button"
        class="mr-3"
        @clickbutton="closeModal"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>
      <ui-button
        v-bind="fetchButtonSpecs()"
        :type="BUTTON_TYPES.submit"
        :disabled="isLoaded && !isVehicleAvailable"
        :loading="isLoading || isLeaving"
        data-test-id="submit-button"
        @clickbutton="changeBookingRules"
      >
        {{ $t('buttons.confirm') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import { computed, ref } from 'vue';
import { BUTTON_TYPES } from '@emobg/web-components';
import { DATE_FORMAT, formatStopWatch, SECONDS_PER_MINUTE } from '@emobg/web-utils';

import ALERT_TYPES from '@/components/Alert/alertTypes';
import BookingRules from '@Bookings/components/BookingRules';
import AlertComponent from '@/components/Alert/AlertComponent';

import { getDurationLabels } from '@/constants/duration';
import {
  endAvailability as endAvailabilityState,
  getVehicleAvailability,
  isError,
  isLoaded,
  isLoading,
  isVehicleAvailable,
  setVehicleAvailable,
  startAvailability as startAvailabilityState,
} from '@Vehicle/store/VehicleAvailability/VehicleAvailabilityMapper';
import { useTheme } from '@/composable/Theme/useTheme';

const MAX_BOOKING_DURATION = 99999;

export default {
  name: 'ChangeTimeSlotModalComponent',
  components: {
    AlertComponent,
    BookingRules,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    availabilityParams: {
      type: Object,
      default: () => ({}),
    },
    bookingRulesData: {
      type: Object,
      default: null,
    },
    datetimepickerManager: {
      type: Object,
      default: null,
    },
    vehicleUuid: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },

  setup({
    bookingRulesData,
    datetimepickerManager,
  }, { emit }) {
    const isLeaving = ref(false);
    const startAvailability = computed(startAvailabilityState);
    const endAvailability = computed(endAvailabilityState);

    const minimumBookingDuration = bookingRulesData ? bookingRulesData.minimumBookingDuration : 0;
    const maximumBookingDuration = bookingRulesData ? bookingRulesData.maximumBookingDuration : 0;

    const durationLabels = getDurationLabels();

    const { fetchButtonSpecs } = useTheme();

    const formattedMinimumDuration = formatStopWatch(
      minimumBookingDuration * SECONDS_PER_MINUTE,
      undefined,
      durationLabels,
    );

    const formattedMaximumDuration = maximumBookingDuration < MAX_BOOKING_DURATION
      ? formatStopWatch(
        maximumBookingDuration * SECONDS_PER_MINUTE,
        undefined,
        durationLabels,
      )
      : '';

    const closeModal = () => {
      emit('update:is-open');
    };

    const changeBookingRules = () => {
      isLeaving.value = true;
      const newSlot = {
        start: datetimepickerManager.getStart(),
        end: datetimepickerManager.getEnd(),
        startAvailability: startAvailability.value,
        endAvailability: endAvailability.value,
      };
      emit('confirm-slot', newSlot);
    };

    return {
      changeBookingRules,
      fetchButtonSpecs,
      formattedMinimumDuration,
      formattedMaximumDuration,
      isLeaving,
      closeModal,
    };
  },

  computed: {
    isError,
    isLoaded,
    isLoading,
    isVehicleAvailable,
  },

  created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    this.ALERT_TYPES = ALERT_TYPES;
    this.setVehicleAvailable();
  },

  methods: {
    getVehicleAvailability,
    setVehicleAvailable,
    onDatetimepickerChange() {
      const dateFormat = date => date.format(DATE_FORMAT.filter);

      const start = dateFormat(this.datetimepickerManager.getStart());
      const end = dateFormat(this.datetimepickerManager.getEnd());
      this.getVehicleAvailability({
        ...this.availabilityParams,
        start,
        end,
        vehicleUuid: this.vehicleUuid,
      });
    },
  },
};
</script>
