<template>
  <ui-modal
    v-bind="$attrs"
    :open="isOpen"
    :header="$t('modal.insurance_info.title')"
    :size="SIZES.small"
    closable
    @close="() => $emit('on:close')"
  >
    <div slot="body">
      <ul>
        <li
          v-for="(insurance, index) in insurancesInfo"
          :key="index"
          :class="{ 'mb-3': index < insurancesInfo.length - 1 }"
        >
          <div class="d-flex align-items-center mb-2">
            <ui-icon
              :size="ICONS_SIZES.xLarge"
              :icon="INSURANCE_ICON[insurance.internal_name]"
              data-test-id="insurance_modal-icon"
            />
            <div class="emobg-body-m-medium pl-2">
              {{ insurance.name }}
            </div>
          </div>
          <p
            class="emobg-body-s-regular MarkdownComponent"
            v-html="compiledMarkdown(insurance.description)"
          />
        </li>
      </ul>
    </div>

    <div
      slot="footer"
      class="row justify-content-end px-3"
    >
      <ui-button
        v-bind="fetchButtonSpecs()"
        @clickbutton="goToInsuranceTab"
      >
        {{ $t('buttons.more_details') }}
      </ui-button>
    </div>
  </ui-modal>
</template>

<script>
import { ICONS } from '@emobg/web-components';
import { useTheme } from '@/composable/Theme/useTheme';

// Routes
import personalProfilesRoutesNames from '@Profile/Personal/router/routes-names';

const INSURANCE_ICON = {
  basic_insurance: ICONS.insuranceBasic,
  carsharing_insurance: ICONS.insuranceCarsharing,
  long_distance_insurance: ICONS.insuranceCarsharing,
  annual_insurance: ICONS.insuranceAnnual,
};

const Marked = require('marked');

export default {
  name: 'InsuranceInfoModal',

  inheritAttrs: true,

  props: {
    insurancesInfo: {
      type: Array,
      default: () => [],
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    const { fetchButtonSpecs } = useTheme();
    return {
      fetchButtonSpecs,
    };
  },

  created() {
    this.INSURANCE_ICON = INSURANCE_ICON;
  },

  methods: {
    compiledMarkdown(markDown) {
      const content = markDown || '';
      return Marked(content, { sanitize: true, breaks: true });
    },
    goToInsuranceTab() {
      this.$router.push({ name: personalProfilesRoutesNames.insurances });
      this.$emit('on:close');
    },
  },
};

</script>
