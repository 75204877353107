var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"LocationHeaderComponent mb-2"},[_c('div',{staticClass:"mb-1"},[_vm._l((_vm.locations),function(location,index){return _c('div',{key:index,class:[
        'd-flex align-items-center',
        {
          'mb-2 mb-sm-1': _vm.hasMultipleLocations
        },
      ],attrs:{"data-test-id":_vm.getDataTestName(index, location)}},[(location.icon)?_c('img',{class:[
          'mr-2',
          {
            'cursor-pointer': _vm.isListening,
          }
        ],attrs:{"src":location.icon,"data-test-id":_vm.getDataTestName(index, location, 'icon'),"alt":"Location icon"},on:{"click":function($event){return _vm.$emit('address-clicked', location)}}}):_c('i',{staticClass:"invisible icons-medium-square mr-2",attrs:{"data-test-id":_vm.getDataTestName(index, location, 'no-icon')}}),_c('p',{staticClass:"row no-gutters flex-wrap align-items-center emobg-font-line-height-large mb-0"},[(_vm.hasMultipleLocations)?_c('strong',{staticClass:"col-12 col-sm-auto emobg-font-weight-bold mr-1",attrs:{"data-test-id":_vm.getDataTestName(index, location, 'label')}},[_vm._v(" "+_vm._s(_vm.getLabel(index))+": ")]):_vm._e(),_c('span',{class:[
            'col-12 col-sm-auto emobg-color-ink-light emobg-font-weight-normal mr-2',
            {
              'cursor-pointer': _vm.isListening,
            }
          ],attrs:{"data-test-id":_vm.getDataTestName(index, location, 'address')},on:{"click":function($event){return _vm.$emit('address-clicked', location)}}},[_vm._v(" "+_vm._s(location.address)+" ")]),(location.action)?_c('a',{staticClass:"col-12 col-sm-auto emobg-font-small emobg-color-primary text-decoration-none",attrs:{"data-test-id":_vm.getDataTestName(index, location, 'action'),"href":"#"},on:{"click":function($event){$event.preventDefault();return location.action.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('Carsharing.views.Booking.Location.link_parking_info_text'))+" ")]):_vm._e()])])}),_c('div',{staticClass:"d-flex flex-wrap py-1"},[_vm._t("extraInfo")],2)],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }