<template>
  <section
    class="col-12 px-5 mt-5"
    data-test-id="my-user-bookings_section_active-bookings-list"
  >
    <h2 class="mb-2">
      {{ $t(`new_booking.tab.active_bookings.content_title${hasOnlyInterventionType ? '-intervention': ''}`) }}
    </h2>
    <p class="emobg-body-m-regular pb-6">
      {{ $t(`new_booking.tab.active_bookings.content_subtitle${hasOnlyInterventionType ? '-intervention': ''}`) }}
    </p>
    <LocationPlaceholder v-if="isLoading" />
    <template v-else>
      <template v-if="isMyBookingsListEmpty">
        <EmptyStateComponent
          has-background
          :src="emptyActiveBookings"
          :title="$t(`new_booking.tab.active_bookings.empty_list.title${hasOnlyInterventionType ? '-intervention': ''}`)"
          :description="$t(`new_booking.tab.active_bookings.empty_list.description${hasOnlyInterventionType ? '-intervention': ''}`)"
          :action="{
            text: $t('empty_list.link'),
            to: isBookButtonVisible && { name: routesNames.home },
          }"
        />
      </template>
      <template v-else>
        <div
          v-for="(booking, index) in activeBookings"
          :key="`request${index}`"
        >
          <Location
            class="px-0"
            type="mybookings"
            v-bind="pick(booking, LOCATION_REQUIRED_PROPS)"
            :booking="booking"
            :map-manager="mapManager"
            :selected-booking-uuid="get(currentBooking, 'uuid')"
            has-new-header
            @selected="onClickBooking(booking)"
          />
        </div>
      </template>
    </template>

    <!-- [CF-540] Remove redundant v-ifs from modals  in MyActiveBookingsTabComponent-->
    <MissingLegalDocumentsComponent
      v-if="modals.missingLegalDocuments.isOpen"
      v-model="modals.missingLegalDocuments.isOpen"
      v-bind="modals.missingLegalDocuments.props"
    />

    <!-- [CF-540] Remove redundant v-ifs from modals  in MyActiveBookingsTabComponent-->
    <EditBookingTimeModal
      v-if="isEditTimeModalOpen"
      :is-open="isEditTimeModalOpen"
      :booking="editTimeBooking"
      :select-locked="false"
      @on:close="closeTimeModal"
    />

    <!-- [CF-540] Remove redundant v-ifs from modals  in MyActiveBookingsTabComponent-->
    <CancelBookingModal
      v-if="isCancelBookingModalOpen"
      :is-open="isCancelBookingModalOpen"
      :booking="cancelBooking"
      @on:close="closeCancelModal"
    />

    <EditDropOffBookingModal
      v-if="isEditDropOffBookingModalOpen && !isEmpty(dropOffBooking)"
      :is-open="isEditDropOffBookingModalOpen"
      :booking="dropOffBooking"
      @on:close="closeEditDropOffModal"
    />

    <!-- [CF-540] Remove redundant v-ifs from modals  in MyActiveBookingsTabComponent-->
    <template v-if="!isEmpty(currentBooking)">
      <EditBookingPeopleModal
        v-if="modals.editBookingPeople.isOpen"
        v-model="modals.editBookingPeople.isOpen"
        v-bind="modals.editBookingPeople"
        @on:close="closeEditPeopleModal"
      />

      <!-- [CF-540] Remove redundant v-ifs from modals  in MyActiveBookingsTabComponent-->
      <AddCarpoolingModal
        v-if="modals.addCarpooling.isOpen"
        v-model="modals.addCarpooling.isOpen"
        v-bind="modals.addCarpooling"
        @on:close="closeAddCarpooling"
      />

      <!-- [CF-540] Remove redundant v-ifs from modals  in MyActiveBookingsTabComponent-->
      <BookingInsuranceModal
        v-if="isEditInsuranceModalOpen"
        :is-open="isEditInsuranceModalOpen"
        :booking="editInsuranceBooking"
        :select-locked="false"
        @on:close="closeUpgradeInsuranceModal"
      />
    </template>
    <FeedbackModalComponent
      :is-open="modals.carPooling.isOpen"
      :primary-call-to-action-loading="modals.carPooling.isLoading"
      :secondary-call-to-action-disabled="modals.carPooling.isLoading"
      :description="$t('modal.cancel_carpooling_trips.description', { date: formattedDate })"
      v-bind="modals.carPooling.props"
    />
    <FeedbackModalComponent
      v-model="modals.feedback.isOpen"
      v-bind="modals.feedback.props"
    />
  </section>
</template>

<script>
// Packages
import {
  DATE_FORMAT, FALLBACK_MESSAGE, formatDate, LOG_TYPE, logger,
} from '@emobg/web-utils';
import { aclService } from '@emobg/access-utils';
import get from 'lodash/get';
import pick from 'lodash/pick';
import size from 'lodash/size';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import flatten from 'lodash/flatten';
import filter from 'lodash/filter';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import sortBy from 'lodash/sortBy';

import { mapActions } from 'vuex';
import { COLORS } from '@emobg/web-components';
// Components
import Location from '@Bookings/components/Location/Location';
import LocationPlaceholder from '@Bookings/components/LocationPlaceholder/LocationPlaceholder';
import EmptyStateComponent from '@/components/EmptyState/EmptyStateComponent';
import EditBookingTimeModal from '@/components/MyBookings/modals/EditBookingTimeModal';
import CancelBookingModal from '@/components/MyBookings/modals/CancelBookingModal';
import EditDropOffBookingModal from '@/components/MyBookings/modals/EditDropOffBookingModal';
import EditBookingPeopleModal from '@/components/MyBookings/modals/EditBookingPeopleModal';
import AddCarpoolingModal from '@/components/MyBookings/modals/AddCarpoolingModal';
import FeedbackModalComponent from '@Shared/components/FeedbackModal/FeedbackModalComponent';
import MissingLegalDocumentsComponent from '@Bookings/components/MissingLegalDocuments/MissingLegalDocumentsComponent';

import * as CostAllocationModule from '@/vue/stores/BookingSummary/CostAllocations';
import {
  canUseCostAllocation,
  getCurrentProfileUuid,
  getSelectedProfile,
  isBusinessProfileActive,
} from '@/stores/User/Profile/ProfileMapper';
import { NAMESPACE as CarpoolingModuleNameSpace } from '@/domains/Carpooling/store/CarpoolingModule';
import {
  fetchMyActiveBookings,
  fetchMyRequestBookings,
  getActiveBookingByUuid,
  getMyActiveBookingByUseCase,
  getMyRequestBookings,
  hasActivePreBookings,
} from '@/stores/Booking/BookingMapper';
import { userData } from '@/stores/User/UserData/UserDataMapper';
import { getCompanyData } from '@/stores/Company/CompanyMapper';
import { getUserInsurance } from '@/stores/User/Insurance/UserInsuranceMapper';
import { fetchCSOperator } from '@/stores/CSOperator/CSOperatorMapper';

// Mixins
import EventHandlerMixin from '@/mixins/EventHandler';

import { useBookingModal } from '@/components/MyBookings/modals/composable/BookingModal';

// Constants
import { BOOKING_EVENTS } from '@Bookings/MyBookings/constants/event-handlers';
import BookingRoutesNames from '@Bookings/router/routes-names';
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { parseApiErrorMessage } from '@/utils/apiHelpers';
import { CREATE_CS_LD_OWN_BOOKINGS, INTERVENTION_BOOKING_TYPE, REGULAR_BOOKING_TYPE } from '@/constants/permissions';
import {
  acceptLegalDocuments,
  getMissingLegalDocuments,
  missingLegalDocuments,
} from '@/stores/User/LegalDocuments/LegalDocumentsMapper';
import { showLowBatteryNotification } from '@/components/MyBookings/tabs/MyActiveBookingsTab';
import BookingInsuranceModal
  from '@/vue/components/organism/BookingInsurance/BookingInsuranceModal/BookingInsuranceModal';
import { composeBookingData } from '@/components/MyBookings/tabs/helpers/myBookings';

import { GTMModalEvents } from '@/constants/gtm';
import { useTrackingGTM } from '@/composable/GTM/gtm';

import { editBookingTimeSuccess, emptyActiveBookings } from '@/utils/publicImages';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';

import { genericErrorArgs, genericSuccessArgs } from '@/constants/defaultModalArgs';
import { storage } from '@Shared/Payments/PaymentMethodsHub/composables/storage';
import {
  EDIT_BOOKING_VARIANT,
  PAYMENT_REDIRECTED,
  RESPONSE_STATUS,
} from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';

const CHARGE_POSTS_WEBSITE = 'https://www.zap-map.com/live/';

export default {
  name: 'MyActiveBookingsTabComponent',

  components: {
    BookingInsuranceModal,
    Location,
    LocationPlaceholder,
    EmptyStateComponent,
    EditBookingTimeModal,
    CancelBookingModal,
    EditDropOffBookingModal,
    EditBookingPeopleModal,
    FeedbackModalComponent,
    AddCarpoolingModal,
    MissingLegalDocumentsComponent,
  },

  mixins: [
    EventHandlerMixin,
  ],

  inject: ['paymentSuccess'],

  props: {
    mapManager: {
      type: Object,
      default: () => ({}),
    },
    bookingTypes: {
      type: Array,
      default: () => [],
    },
    lastBookingSelected: {
      type: Object,
      default: () => ({}),
    },
  },

  setup() {
    const {
      isModalOpen: isEditTimeModalOpen,
      openModal: openEditTimeModal,
      closeModal: closeEditTimeModal,
      bookingDataModal: editTimeBooking,
    } = useBookingModal();
    const {
      isModalOpen: isCancelBookingModalOpen,
      openModal: openCancelBookingModal,
      closeModal: closeCancelBookingModal,
      bookingDataModal: cancelBooking,
    } = useBookingModal();
    const {
      isModalOpen: isEditDropOffBookingModalOpen,
      openModal: openEditDropOffBookingModal,
      closeModal: closeEditDropOffBookingModal,
      bookingDataModal: dropOffBooking,
    } = useBookingModal();
    const {
      isModalOpen: isEditInsuranceModalOpen,
      openModal: openEditInsuranceModal,
      closeModal: closeEditInsuranceModal,
      bookingDataModal: editInsuranceBooking,
    } = useBookingModal();
    const { trackModalView, trackPageView } = useTrackingGTM();
    const { notifyError, notifyWarning } = useNotifications();

    return {
      isEditTimeModalOpen,
      openEditTimeModal,
      closeEditTimeModal,
      editTimeBooking,
      isCancelBookingModalOpen,
      openCancelBookingModal,
      closeCancelBookingModal,
      cancelBooking,
      isEditDropOffBookingModalOpen,
      openEditDropOffBookingModal,
      closeEditDropOffBookingModal,
      dropOffBooking,
      trackModalView,
      trackPageView,
      notifyError,
      notifyWarning,
      isEditInsuranceModalOpen,
      openEditInsuranceModal,
      closeEditInsuranceModal,
      editInsuranceBooking,
    };
  },

  data() {
    return {
      isLoading: true,
      currentBooking: undefined,
      activeBookings: [],
      routesNames: BookingRoutesNames,
      modals: {
        feedback: {
          isOpen: false,
          props: {},
        },
        missingLegalDocuments: {
          isOpen: false,
          props: {},
        },
        editBookingPeople: {
          isOpen: false,
        },
        addCarpooling: {
          isOpen: false,
        },
        carPooling: {
          isOpen: false,
          isLoading: false,
          props: {
            title: this.$t('modal.cancel_carpooling_trips.title'),
            type: COLORS.danger,
            primaryCallToAction: this.submitCancelCarpoolingTrips,
            primaryCallToActionText: this.$t('modal.cancel_carpooling_trips.confirm'),
            primaryButtonType: THEME_BUTTON_TYPES.DESTRUCTIVE,
            secondaryCallToAction: this.closeConfirmCancelCarpoolingModal,
            secondaryCallToActionText: this.$t('modal.cancel_carpooling_trips.back'),
            errorMessage: '',
          },
        },
      },
    };
  },

  computed: {
    userData,
    getCompanyData,
    getCurrentProfileUuid,
    getUserInsurance,
    canUseCostAllocation,
    getActiveBookingByUuid,
    getMyRequestBookings,
    getMyActiveBookingByUseCase,
    hasActivePreBookings,
    missingLegalDocuments,
    isBusinessProfileActive,
    getSelectedProfile,

    isMyBookingsListEmpty() {
      return isEmpty(this.activeBookings);
    },

    hasOnlyInterventionType() {
      return isEqual(this.bookingTypes, [BOOKING_TYPES.intervention]);
    },

    bookingTypesAllowed() {
      const bookingTypeByPermission = {
        [REGULAR_BOOKING_TYPE]: [BOOKING_TYPES.carsharing, BOOKING_TYPES.longDistance],
        [INTERVENTION_BOOKING_TYPE]: [BOOKING_TYPES.intervention],
      };

      return flatten(filter(bookingTypeByPermission, (_bookingType, permission) => aclService.hasUserPermissions(permission)));
    },

    isBookButtonVisible() {
      return (
        aclService.hasUserPermissions(CREATE_CS_LD_OWN_BOOKINGS)
        && !this.hasOnlyInterventionType
      );
    },

    formattedDate() {
      if (isNil(this.currentBooking)) {
        return FALLBACK_MESSAGE.dash;
      }

      return formatDate(this.currentBooking.start, DATE_FORMAT.dob);
    },
  },

  watch: {
    async paymentSuccess(value) {
      const editMatchingName = value.response.matchingName;
      const variant = editMatchingName.split(EDIT_BOOKING_VARIANT.splitter).pop();
      const editMatchingUuid = editMatchingName.split(EDIT_BOOKING_VARIANT.splitter).shift();

      const matchingNameIsInActiveBookings = this.activeBookings.find(({ uuid }) => uuid === editMatchingUuid);

      if (matchingNameIsInActiveBookings) {
        if (value.isValid) {
          if (variant === EDIT_BOOKING_VARIANT.editInsurance) {
            this.closeUpgradeInsuranceModal({ isEdited: true });
          }

          if (variant === EDIT_BOOKING_VARIANT.editTime) {
            this.closeTimeModal({ isEdited: true });
          }

          this.showSuccessFeedback();
        }

        // only show refused feedback upon redirect or challenge/identify flows, as the rest will be shown on the payment method modal itself
        const additionalStepResponses = [RESPONSE_STATUS.challenge, RESPONSE_STATUS.identify];

        if (
          !value.isValid && (storage.get(PAYMENT_REDIRECTED)
          || additionalStepResponses.includes(value.response.loopOrigin)
          )) {
          this.showRefusedFeedback(value.response);

          storage.remove(PAYMENT_REDIRECTED);

          if (variant === EDIT_BOOKING_VARIANT.editTime) {
            await this.loadActiveBookings();
          }
        }
      }
    },

    getSelectedProfile(newUuid) {
      if (newUuid) {
        this.loadActiveBookings();
      }
    },
  },

  async created() {
    this.emptyActiveBookings = emptyActiveBookings;
    this.LOCATION_REQUIRED_PROPS = ['bookingUuid', 'bookingPeriod', 'isBookingRequest', 'location', 'destinationLocation'];
    await this.loadActiveBookings();

    if (this.canUseCostAllocation && this.hasActivePreBookings) {
      await this.getCostAllocations({
        companyUuid: get(this, 'getCompanyData.uuid'),
        payload: {
          filter: 'booking',
        },
      });
    }

    this.eventHandler.$on(BOOKING_EVENTS.editBookingTimeModalOpen, this.openEditBookingTime);
    this.eventHandler.$on(BOOKING_EVENTS.cancelBookingModalOpen, this.openCancelBooking);
    this.eventHandler.$on(BOOKING_EVENTS.editBookingDropOffModalOpen, this.openEditBookingDropOff);
    this.eventHandler.$on(BOOKING_EVENTS.editBookingPeopleModalOpen, this.openAdditionalVehicleUsers);
    this.eventHandler.$on(BOOKING_EVENTS.submitEditBooking, this.loadActiveBookings);
    this.eventHandler.$on(BOOKING_EVENTS.editBookingCancelCarPooling, this.openConfirmCancelCarpoolingModal);
    this.eventHandler.$on(BOOKING_EVENTS.editBookingAddCarpooling, this.openAddCarpoolingModal);
    this.eventHandler.$on(BOOKING_EVENTS.editBookingUpgradeInsurance, this.upgradeInsuranceModal);

    this.successModalArgs = {
      ...genericSuccessArgs(this.$t),
      image: editBookingTimeSuccess,
      title: this.$t('modal.edit_booking_time.success.title'),
      primaryCallToAction: () => {
        this.modals.feedback.props.isOpen = false;
      },
    };
  },

  beforeMount() {
    const unwatch = this.$watch('activeBookings', showLowBatteryNotification(() => {
      this.notifyWarning({
        text: this.$t('carsharing_personal.low_battery_alert'),
        textAction: this.$t('common.more_info'),
        action: () => {
          window.open(CHARGE_POSTS_WEBSITE, '_blank');
        },
      });
      unwatch();
    }));
  },

  beforeDestroy() {
    this.eventHandler.$off(BOOKING_EVENTS.editBookingTimeModalOpen, this.openEditBookingTime);
    this.eventHandler.$off(BOOKING_EVENTS.cancelBookingModalOpen, this.openCancelBooking);
    this.eventHandler.$off(BOOKING_EVENTS.editBookingDropOffModalOpen, this.openEditBookingDropOff);
    this.eventHandler.$off(BOOKING_EVENTS.editBookingPeopleModalOpen, this.openEditBookingDropOff);
    this.eventHandler.$off(BOOKING_EVENTS.submitEditBooking, this.loadActiveBookings);
    this.eventHandler.$off(BOOKING_EVENTS.editBookingCancelCarPooling, this.openConfirmCancelCarpoolingModal);
    this.eventHandler.$off(BOOKING_EVENTS.editBookingAddCarpooling, this.openAddCarpoolingModal);
    this.eventHandler.$off(BOOKING_EVENTS.editBookingUpgradeInsurance, this.upgradeInsuranceModal);
  },

  methods: {
    get,
    pick,
    size,
    isNil,
    isEmpty,
    find,
    fetchMyActiveBookings,
    fetchMyRequestBookings,
    fetchCSOperator,
    acceptLegalDocuments,
    getMissingLegalDocuments,

    ...mapActions(CostAllocationModule.nameSpace, {
      getCostAllocations: CostAllocationModule.ACTIONS.getCostAllocations,
    }),

    ...mapActions(CarpoolingModuleNameSpace, [
      'cancelBookingRideshareTrips',
    ]),

    async loadActiveBookings() {
      this.isLoading = true;

      try {
        let usesPreBooking = false;
        const companyOperatorUuid = get(this.userData, 'company.dedicated_fleet_cs_operator');

        if (companyOperatorUuid) {
          const csOperator = await this.fetchCSOperator(companyOperatorUuid);
          usesPreBooking = get(csOperator, 'configuration.uses_pre_booking');
        }

        const payloadActiveBookings = {
          bookingParams: {
            profileUuid: this.getCurrentProfileUuid,
            booking_types: this.bookingTypesAllowed,
            items: 100,
          },
        };

        const payloadPreBookings = {
          profileUuid: this.getCurrentProfileUuid,
        };

        await Promise.all([
          usesPreBooking ? this.fetchMyRequestBookings(payloadPreBookings) : null,
          this.fetchMyActiveBookings(payloadActiveBookings),
        ]);

        const userBookings = this.getMyActiveBookingByUseCase(this.bookingTypes);

        const csOperatorsUuids = uniq(map(userBookings, 'cs_operator_uuid'));
        const csOperators = await Promise.all(map(csOperatorsUuids, csOperator => this.fetchCSOperator(csOperator)));

        const activeBookings = composeBookingData({
          bookings: userBookings,
          userHasFullCoveredInsurance: get(this, 'getUserInsurance.fully_covered'),
          csOperators,
          isBusinessProfileActive: this.isBusinessProfileActive,
        });
        this.activeBookings = sortBy(activeBookings, 'start');
        this.eventHandler.$emit(BOOKING_EVENTS.cleanMap);

        this.currentBooking = null;
        this.eventHandler.$emit(BOOKING_EVENTS.setLastBookingSelected, null);

        this.eventHandler.$emit(BOOKING_EVENTS.createBookingMarkers);
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
        const errorMessage = parseApiErrorMessage(this.$t, this.$i18n, error);
        this.notifyError({
          text: errorMessage,
        });
      }

      this.isLoading = false;
    },

    onClickBooking(booking) {
      this.currentBooking = booking;
      if (get(this, 'lastBookingSelected.uuid') === booking.uuid) {
        return;
      }

      this.eventHandler.$emit(
        BOOKING_EVENTS.createAndFocusSelectedBookingMarker,
        booking,
      );

      this.eventHandler.$emit(BOOKING_EVENTS.setLastBookingSelected, booking);
    },

    // TODO: [CF-373] Refactor modal functions in MyActiveBookingsTabComponent
    async openEditBookingTime(uuid) {
      const booking = find(this.activeBookings, { uuid });
      const shouldContinueFlow = await this.shouldContinueFlow(
        uuid,
        () => {
          this.openEditTimeModal({ booking });
          this.trackModalView({ modalName: GTMModalEvents.editBookingTime });
        },
      );

      if (!shouldContinueFlow) {
        return;
      }

      this.openEditTimeModal({ booking });
      this.trackModalView({ modalName: GTMModalEvents.editBookingTime });
    },

    closeTimeModal({ isEdited }) {
      this.closeEditTimeModal();
      this.trackPageView();

      if (isEdited) {
        this.loadActiveBookings();
      }
    },

    // TODO: [CF-373] Refactor modal functions in MyActiveBookingsTabComponent
    async openCancelBooking(uuid) {
      const booking = find(this.activeBookings, { uuid });
      const shouldContinueFlow = await this.shouldContinueFlow(
        uuid,
        () => {
          this.openCancelBookingModal({ booking });
          this.trackModalView({ modalName: GTMModalEvents.cancelBooking });
        },
      );

      if (!shouldContinueFlow) {
        return;
      }

      this.trackModalView({ modalName: GTMModalEvents.cancelBooking });
      this.openCancelBookingModal({ booking });
    },

    closeCancelModal(isCanceling) {
      this.closeCancelBookingModal();

      if (isCanceling) {
        this.loadActiveBookings();
      }
    },

    openEditBookingDropOff(uuid) {
      const booking = find(this.activeBookings, { uuid });
      this.openEditDropOffBookingModal({ booking });
    },

    closeEditDropOffModal({ isEditing }) {
      this.closeEditDropOffBookingModal();

      if (isEditing) {
        this.loadActiveBookings();
      }
    },

    openAdditionalVehicleUsers(bookingData) {
      const {
        uuid,
        isAdditionalDriverEnabled,
        isAdditionalPassengersEnabled,
      } = bookingData;

      this.currentBooking = find(this.activeBookings, { uuid });

      this.modals.editBookingPeople = {
        drivers: get(this.currentBooking, 'additionalDrivers'),
        passengers: get(this.currentBooking, 'passengers'),
        availableSeats: get(this.currentBooking, 'free_seats'),
        isAdditionalDriverEnabled: Boolean(isAdditionalDriverEnabled),
        isAdditionalPassengersEnabled: Boolean(isAdditionalPassengersEnabled),
        bookingUuid: get(this.currentBooking, 'uuid'),
        bookingType: get(this.currentBooking, 'cs_booking_use_case.booking_type', BOOKING_TYPES.carsharing),
        ownerEmail: get(this.currentBooking, 'user.email'),
        userUuid: get(this.currentBooking, 'user.uuid'),
        isPreBooking: get(this.currentBooking, 'isBookingRequest'),
        isOpen: true,
      };
      this.trackModalView({ modalName: GTMModalEvents.editBookingPeople });
    },

    openConfirmCancelCarpoolingModal(uuid) {
      this.currentBooking = find(this.activeBookings, { uuid });
      this.modals.carPooling.isOpen = true;
      this.trackModalView({ modalName: GTMModalEvents.cancelBookingCarpooling });
    },

    closeConfirmCancelCarpoolingModal() {
      this.modals.carPooling.isOpen = false;
      this.modals.carPooling.props.errorMessage = '';
      this.trackPageView();
    },

    openUpgradeInsuranceModal({ currentBooking }) {
      const booking = currentBooking;
      this.openEditInsuranceModal({ booking });
    },

    async submitCancelCarpoolingTrips() {
      this.modals.carPooling.isLoading = true;
      this.modals.carPooling.props.errorMessage = '';

      try {
        await this.cancelBookingRideshareTrips(this.currentBooking.uuid);
        this.modals.carPooling.isOpen = false;
        this.trackPageView();
        this.loadActiveBookings();
      } catch (error) {
        logger.message(error, LOG_TYPE.error);
        this.modals.carPooling.props.errorMessage = this.$t('modal.cancel_carpooling_trips.error_submit');
      } finally {
        this.modals.carPooling.isLoading = false;
      }
    },

    closeEditPeopleModal({ isEdited }) {
      this.modals.editBookingPeople.isOpen = false;
      this.trackPageView();

      if (isEdited) {
        this.loadActiveBookings();
      }
    },

    openAddCarpoolingModal(uuid) {
      this.currentBooking = find(this.activeBookings, { uuid });

      this.modals.addCarpooling = {
        ...pick(this.currentBooking, ['start', 'end']),
        driversCount: size(get(this, 'currentBooking.passengers')),
        passengersCount: size(get(this, 'currentBooking.additionalDrivers')),
        locationName: get(this, 'currentBooking.location.name'),
        availableSeats: get(this, 'currentBooking.free_seats'),
        bookingUuid: get(this, 'currentBooking.uuid'),
        booking: this.currentBooking,
        isOpen: true,
      };
      this.trackModalView({ modalName: GTMModalEvents.addCarpoolingToBooking });
    },

    // TODO: [CF-373] Refactor modal functions in MyActiveBookingsTabComponent
    async upgradeInsuranceModal(uuid) {
      this.currentBooking = find(this.activeBookings, { uuid });
      const shouldContinueFlow = await this.shouldContinueFlow(
        uuid,
        () => {
          this.openUpgradeInsuranceModal({ currentBooking: this.currentBooking });
          this.trackModalView({ modalName: GTMModalEvents.editBookingInsurance });
        },
      );

      if (!shouldContinueFlow) {
        return;
      }

      this.openUpgradeInsuranceModal({ currentBooking: this.currentBooking });
      this.trackModalView({ modalName: GTMModalEvents.editBookingInsurance });
    },

    closeAddCarpooling({ isEdited }) {
      this.modals.addCarpooling.isOpen = false;
      this.trackPageView();

      if (isEdited) {
        this.loadActiveBookings();
      }
    },

    closeUpgradeInsuranceModal({ isEdited }) {
      this.closeEditInsuranceModal();
      this.trackPageView();

      if (isEdited) {
        this.loadActiveBookings();
      }
    },

    async shouldContinueFlow(uuid, callback) {
      this.currentBooking = find(this.activeBookings, { uuid });
      const errorModalSetup = {
        isOpen: true,
        props: {
          ...genericErrorArgs(this.$t),
          primaryCallToAction: () => {
            this.modals.feedback.isOpen = false;
          },
        },
      };
      const hasMissingLegalDocuments = !isEmpty(await this.getMissingLegalDocuments({
        operatorUuid: get(this.currentBooking, 'csOperator.uuid'),
      }));
      if (!hasMissingLegalDocuments) {
        return true;
      }

      if (size(this.missingLegalDocuments)) {
        this.modals.missingLegalDocuments = {
          isOpen: true,
          props: {
            documents: this.missingLegalDocuments,
            onCancel: () => {
              this.modals.missingLegalDocuments.isOpen = false;

              return false;
            },
            onAccept: async ({ acceptedUuids }) => {
              const areAccepted = !!(await this.acceptLegalDocuments({
                legalDocumentsUuid: acceptedUuids,
              }));

              this.modals.missingLegalDocuments.isOpen = false;

              if (!areAccepted) {
                this.modals.feedback = errorModalSetup;
                return false;
              }

              callback();

              return true;
            },
          },
        };

        return false;
      }

      return true;
    },

    showSuccessFeedback() {
      this.modals.feedback.props = {
        ...this.successModalArgs,
        isOpen: true,
      };
    },

    showRefusedFeedback(response) {
      this.modals.feedback.props = {
        ...genericErrorArgs(this.$t),
        title: response.providerMessage || this.$t('modal.confirm_payment.error.title'),
        description: '',
        primaryCallToAction: () => {
          this.modals.feedback.props.isOpen = false;
        },
        isOpen: true,
      };
    },
  },
};
</script>
