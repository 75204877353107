<template>
  <ui-modal
    :open="value"
    :size="SIZES.small"
    :header="$t('BookingView.RecurringBookingsModal.title')"
    data-test-id="recurring_bookings-modal"
  >
    <div slot="body">
      <ui-validate @status="({ detail }) => isFormValid = detail.isValid && detail.areAllValidated">
        <div class="d-flex flex-column">
          <p>
            {{ $t('BookingView.RecurringBookingsModal.subtitle') }}
          </p>
          <div class="px-1 mb-3 emobg-font-small">
            <h6 class="emobg-font-small">
              {{ $t('BookingView.RecurringBookingsModal.bookingStart') }}
            </h6>
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center">
                <ui-icon
                  :icon="ICONS.calendar"
                  :size="ICONS_SIZES.xSmall"
                  class="mr-2"
                />
                <span class="emobg-font-weight-bold mr-2">
                  {{ $t('BookingView.RecurringBookingsModal.from') }}
                </span> {{ formattedDates.start }}
              </div>
              <div class="d-flex align-items-center">
                <ui-icon
                  :icon="ICONS.calendar"
                  :size="ICONS_SIZES.xSmall"
                  class="mr-2"
                />
                <span class="emobg-font-weight-bold mr-2">
                  {{ $t('BookingView.RecurringBookingsModal.to') }}
                </span> {{ formattedDates.end }}
              </div>
            </div>
          </div>
          <ui-select
            v-validate="{ isRequired: true }"
            :value="selectedRecurrenceOption"
            :size="SIZES.small"
            :options.prop="recurrenceOptions"
            :label="$t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.label')"
            :placeholder="$t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.placeholder')"
            name="recurrenceOptionSelect"
            @selectoption="({ detail }) => selectedRecurrenceOption = detail"
          />
          <p
            v-if="!recurrenceOptionIsValid"
            class="emobg-font-small emobg-color-danger"
          >
            {{ $t('BookingView.RecurringBookingsModal.recurrenceOptionsSelect.errorCaption', { days: get(selectedRecurrenceOptionData, 'daysRange')}) }}
          </p>
          <div
            v-if="selectedRecurrenceOption === recurrenceOptionsKeys.custom"
            class="d-flex flex-column mt-2"
          >
            <ui-select-multiple
              v-validate="{ isRequired: true }"
              :values.prop="selectedCustomOption"
              :label="$t('BookingView.RecurringBookingsModal.customOptionsSelect.label')"
              :placeholder="$t('BookingView.RecurringBookingsModal.customOptionsSelect.placeholder')"
              :options.prop="customRecurrenceOptions"
              :size="SIZES.small"
              name="customRecurrenceOptionSelect"
              @selectoptions="({ detail }) => selectedCustomOption = detail"
            />
          </div>
          <MuiDatePicker
            :label="$t('BookingView.RecurringBookingsModal.recurrenceEndLabel')"
            :date.prop="selectedLimitDate"
            v-bind="datetimepickerProps"
            :valid-date-range.prop="dateValidRange"
            :size="SIZES.small"
            skiptime
            class="mt-3"
            data-test-id="recurrence_expiration_date-datetimepicker"
            @update:date="value => selectedLimitDate = value"
          />
          <AlertComponent
            :type="alertTypes.info"
            class="mt-3"
          >
            {{
              isFormValid
                ? $t('BookingView.RecurringBookingsModal.AlertComponent.recurrenceCreatingSetInfo', { recurrenceCardinal, })
                : $t('BookingView.RecurringBookingsModal.AlertComponent.recurrenceDefaultMessage', { recurrenceLimit, })
            }}
          </AlertComponent>
          <AlertComponent
            v-if="!recurrenceLimitIsValid"
            :type="alertTypes.warning"
            class="mt-3"
            data-test-id="invalid_recurring_booking-alert"
          >
            {{
              $t('BookingView.RecurringBookingsModal.AlertComponent.recurrenceLimitMessage', {
                recurrenceCardinal,
                recurrenceLimit
              })
            }}
          </AlertComponent>
        </div>
      </ui-validate>
    </div>
    <div
      slot="footer"
      class="d-flex flex-wrap justify-content-end"
      data-test-id="ui-modal--footer"
    >
      <ui-button
        :face="FACES.outline"
        class="mr-3"
        data-test-id="cancel-button"
        @clickbutton="$emit('on:close')"
      >
        {{ $t('buttons.cancel') }}
      </ui-button>

      <ui-button
        v-bind="fetchButtonSpecs()"
        :disabled="!isFormValid"
        data-test-id="confirm-button"
        @clickbutton="onClickConfirm"
      >
        {{ $t('buttons.confirm') }}
      </ui-button>
    </div>
  </ui-modal>
</template>
<script>
import clone from 'lodash/clone';
import map from 'lodash/map';
import find from 'lodash/find';
import isNil from 'lodash/isNil';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import camelCase from 'lodash/camelCase';
import pick from 'lodash/pick';
import invoke from 'lodash/invoke';
import some from 'lodash/some';
import last from 'lodash/last';
import size from 'lodash/size';

import { MuiDatePicker, Validate } from '@emobg/vue-base';
import { DATE_FORMAT } from '@emobg/web-utils';

import TooltipComponent from '@/components/Tooltip/TooltipComponent';
import AlertComponent from '@/components/Alert/AlertComponent';
import { fetchCSOperator, getCurrentCSOperator } from '@/stores/CSOperator/CSOperatorMapper';
import { fetchBookingRules } from '@/stores/Booking/BookingMapper';
import { getCurrentProfileUuid } from '@/stores/User/Profile/ProfileMapper';
import { userData } from '@/stores/User/UserData/UserDataMapper';

import languageService from '@/services/Language';

import { DATETIME_FORMATS } from '@/constants/datetimeFormats';
import { DATE_UNITS } from '@/constants/dates';
import ALERT_TYPES from '@/components/Alert/alertTypes';
import { bookingRulesOperatorConfigs } from '@/helpers/csOperator/configuration';
import { REQUIRED_CS_OPERATOR_CONFIGS } from '@/components/BookingDatetimepicker/constants/csOperatorConfigRequired';
import { BOOKING_TYPES } from '@/constants/bookingTypes';
import { useTheme } from '@/composable/Theme/useTheme';
import { calculateRecurrencePeriods, generateWeekDaysOptions } from './helpers/dates';
import { RECURRENCE_OPTIONS, RECURRENCE_OPTIONS_DATA } from './constants/recurrenceOptions';

export default {
  name: 'RecurringBookingsModal',
  components: {
    AlertComponent,
    MuiDatePicker,
  },
  directives: {
    Validate,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    datetimepickerManager: {
      type: Object,
      required: true,
    },
    recurrenceLimit: {
      type: Number,
      required: true,
    },
    recurrenceOption: {
      type: String,
      default: RECURRENCE_OPTIONS.weekly,
    },
    customOption: {
      type: Array,
      default: () => ([]),
    },
    limitDate: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
  data() {
    return {
      isFormValid: false,
      datetimepickerProps: undefined,
      selectedRecurrenceOption: clone(this.recurrenceOption),
      selectedCustomOption: clone(this.customOption),
      selectedLimitDate: clone(this.limitDate),
    };
  },
  computed: {
    getCurrentProfileUuid,
    userData,

    dateValidRange() {
      const futureLimitDate = moment(this.datetimepickerManager.end).add(get(this.datetimepickerProps, 'maximumFutureBookingTime'), DATE_UNITS.minutes);

      const calculatePeriodsArguments = {
        recurrenceOption: this.selectedRecurrenceOption,
        recurrenceCardinal: this.recurrenceLimit,
        startBooking: this.datetimepickerManager.start,
        endBooking: this.datetimepickerManager.end,
        customOptions: get(this, 'selectedCustomOption'),
      };

      const periods = calculateRecurrencePeriods(calculatePeriodsArguments);
      const lastPeriod = moment(get(last(periods), 'start'));
      const endMaxRecurringBookings = futureLimitDate.isSameOrBefore(lastPeriod.startOf(DATE_UNITS.day))
        ? futureLimitDate
        : lastPeriod;

      return {
        start: moment(this.datetimepickerManager.start).add(get(this.datetimepickerProps, 'minimumBookingDuration'), DATE_UNITS.minutes),
        end: endMaxRecurringBookings,
      };
    },
    formattedDates() {
      return {
        start: this.datetimepickerManager.start.format(DATETIME_FORMATS.longPrettyDateTimeWithHours),
        end: this.datetimepickerManager.end.format(DATETIME_FORMATS.longPrettyDateTimeWithHours),
      };
    },
    currentLanguage() {
      return languageService.getLanguage();
    },
    bookingDiffDays() {
      return this.datetimepickerManager.end.diff(this.datetimepickerManager.start, DATE_UNITS.days);
    },
    selectedRecurrenceOptionData() {
      return find(this.recurrenceOptions, ['value', this.selectedRecurrenceOption]);
    },
    recurrenceOptions() {
      return map(RECURRENCE_OPTIONS_DATA, recurrenceOptionData => ({
        ...recurrenceOptionData,
        disabled: this.bookingDiffDays > recurrenceOptionData.daysRange,
        component: this.bookingDiffDays > recurrenceOptionData.daysRange ? TooltipComponent : null,
      }));
    },
    recurrenceCardinal() {
      if (some([this.datetimepickerManager.start, this.selectedLimitDate], isNil)) {
        return 0;
      }

      const calculatePeriodsArguments = {
        recurrenceOption: this.selectedRecurrenceOption,
        recurrenceCardinal: this.recurrenceLimit,
        startBooking: this.datetimepickerManager.start,
        endBooking: this.datetimepickerManager.end,
        customOptions: get(this, 'selectedCustomOption'),
        limitDate: moment(this.selectedLimitDate),
      };

      return size(calculateRecurrencePeriods(calculatePeriodsArguments));
    },
    recurrenceOptionIsValid() {
      return isNil(get(this.selectedRecurrenceOptionData, 'daysRange'))
        ? true
        : this.bookingDiffDays <= this.selectedRecurrenceOptionData.daysRange;
    },
    recurrenceLimitIsValid() {
      return isNumber(this.recurrenceLimit)
        ? this.recurrenceCardinal <= this.recurrenceLimit
        : false;
    },
  },
  watch: {
    dateValidRange(newValidRange) {
      if (this.selectedLimitDate.isBefore(newValidRange.start)) {
        this.selectedLimitDate = newValidRange.start;
      } else if (this.selectedLimitDate.isAfter(newValidRange.end)) {
        this.selectedLimitDate = newValidRange.end;
      }
    },
  },
  async created() {
    this.recurrenceOptionsKeys = RECURRENCE_OPTIONS;
    this.timeFormat = DATE_FORMAT.timeOfDay;
    this.dateFormat = DATETIME_FORMATS.datetimepickerDisplay;
    this.dateUnits = DATE_UNITS;
    this.alertTypes = ALERT_TYPES;
    this.customRecurrenceOptions = generateWeekDaysOptions(this.currentLanguage);

    await this.setRules();
  },
  methods: {
    get,
    getCurrentCSOperator,
    fetchCSOperator,
    fetchBookingRules,

    syncLimitDate() {
      if (this.datetimepickerManager.end.isSameOrAfter(this.selectedLimitDate)) {
        this.selectedLimitDate = this.datetimepickerManager.end;
      }
    },
    async setRules() {
      const company = invoke(this.userData, 'getCompany');
      const operatorUuid = get(company, 'dedicated_fleet_cs_operator') || invoke(this.userData, 'getCarsharingOperatorUUID');

      this.bookingOperator = await this.fetchCSOperator(operatorUuid);

      const csOperatorConfig = get(this.bookingOperator, 'configuration');

      const bookingOperatorConfigs = bookingRulesOperatorConfigs({
        csOperatorConfig,
        bookingType: BOOKING_TYPES.carsharing,
      });

      this.datetimepickerProps = {
        ...pick(bookingOperatorConfigs, map(REQUIRED_CS_OPERATOR_CONFIGS, camelCase)),
      };
    },
    onClickConfirm() {
      const customOption = this.selectedRecurrenceOption === RECURRENCE_OPTIONS.custom
        ? this.selectedCustomOption
        : [];

      const calculatePeriodsArguments = {
        recurrenceOption: this.selectedRecurrenceOption,
        recurrenceCardinal: this.recurrenceCardinal,
        startBooking: this.datetimepickerManager.start,
        endBooking: this.datetimepickerManager.end,
        limitDate: null,
        customOptions: customOption,
      };

      const recurrenceArguments = {
        customOption,
        datetimepickerManager: this.datetimepickerManager,
        recurrenceOption: this.selectedRecurrenceOption,
        limitDate: this.selectedLimitDate,
        periods: calculateRecurrencePeriods(calculatePeriodsArguments),
      };

      this.$emit('on:apply-recurrence', recurrenceArguments);
    },
  },
};
</script>
