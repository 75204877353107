<script>
import { THEME_BUTTON_TYPES } from '@/composable/Theme/constants/specs';
import { useTheme } from '@/composable/Theme/useTheme';

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    buttonType: {
      type: String,
      default: THEME_BUTTON_TYPES.PRIMARY,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      default: () => {},
    },
    dataTestId: {
      type: String,
      default: 'action-button',
    },
  },
  setup() {
    const { fetchButtonSpecs } = useTheme();
    return { fetchButtonSpecs };
  },
};
</script>
<template>
  <ui-button
    v-bind="fetchButtonSpecs({ buttonType })"
    :disabled="disabled"
    :size="SIZES.small"
    :data-test-id="dataTestId"
    @clickbutton="onClick"
  >
    {{ text }}
  </ui-button>
</template>
