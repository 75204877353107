<template>
  <div class="d-flex flex-column">
    <ui-button-segments
      :value="bookingMode"
      :options.prop="bookingModeOptions"
      class="mb-1 d-flex"
      data-test-id="booking_mode-toggle"
      @clickbuttonsegment="({ detail }) => $emit('update:booking-mode-change', detail)"
    />
    <div
      v-if="!isEmpty(selectedRecurringBooking)"
      class="d-flex"
      data-test-id="selected_recurring_booking_start-container"
    >
      <ui-icon
        class="mr-2"
        :icon="ICONS.calendar"
        :size="ICONS_SIZES.small"
      />
      <span class="emobg-font-weight-bold mr-1">
        {{ $t('BookingView.RecurringBookingsModal.BookingModeSelector.recurringBookingSelected.endLabel') }}:
      </span>
      <span
        class="emobg-color-ink-light"
        data-test-id="selected_recurring_booking_start-text"
      >
        {{ formatDate(selectedRecurringBooking.limitDate, datetimeFormats.defaultDash) }}
      </span>
    </div>
  </div>
</template>
<script>
import isEmpty from 'lodash/isEmpty';
import { formatDate } from '@emobg/web-utils';

import { BOOKING_MODES } from '@/constants/bookingModes';
import { DATETIME_FORMATS } from '@/constants/datetimeFormats';

export default {
  name: 'BookingModeSelector',
  props: {
    bookingMode: {
      type: String,
      default: BOOKING_MODES.regularBooking,
    },
    selectedRecurringBooking: {
      type: Object,
      default: () => ({}),
    },
  },
  created() {
    this.datetimeFormats = DATETIME_FORMATS;
    this.bookingModeOptions = [
      {
        label: this.$t('BookingView.RecurringBookingsModal.BookingModeSelector.selectorSegmentButton.options.regularBooking.label'),
        value: BOOKING_MODES.regularBooking,
      },
      {
        label: this.$t('BookingView.RecurringBookingsModal.BookingModeSelector.selectorSegmentButton.options.recurringBooking.label'),
        value: BOOKING_MODES.recurringBooking,
      }];
  },
  methods: {
    isEmpty,
    formatDate,
  },
};
</script>
