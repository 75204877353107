import { useTranslations } from '@/composable/App/Translations';

const { $t } = useTranslations();

export const AVAILABILTY_SUGGESTIONS_OPTIONS = {
  fixStart: 'fix_start',
  fixEnd: 'fix_end',
  none: 'none',
};

export const AVAILABILTY_SUGGESTIONS_OPTIONS_SELECT = [
  {
    label: $t('BookingView.AvailabilitySuggestionsModal.suggestionOptionSelect.options.fix_start'),
    value: AVAILABILTY_SUGGESTIONS_OPTIONS.fixStart,
  },
  {
    label: $t('BookingView.AvailabilitySuggestionsModal.suggestionOptionSelect.options.fix_end'),
    value: AVAILABILTY_SUGGESTIONS_OPTIONS.fixEnd,
  },
  {
    label: $t('BookingView.AvailabilitySuggestionsModal.suggestionOptionSelect.options.none'),
    value: AVAILABILTY_SUGGESTIONS_OPTIONS.none,
  },
];
