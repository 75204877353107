<script>
import truncate from 'lodash/truncate';
import inRange from 'lodash/inRange';

const DATA_TEST_ID = 'location-header-base';

export default {
  name: 'LocationHeaderBaseComponent',

  props: {
    /**
     * Required props:
     * - address {string}
     * - icon {string}
     * - action {function} - It will be used when user clicks to "Parking info"
     */
    locations: {
      type: Array,
      required: false,
      default: null,
    },
  },

  computed: {
    hasMultipleLocations() {
      return this.locations.length > 1;
    },
    isListening() {
      return this.$listeners['address-clicked'];
    },
  },

  methods: {
    isFirstOne(index) {
      return !index;
    },
    isLastOne(index) {
      return index === this.locations.length - 1;
    },
    getLabel(index) {
      let label = '';
      if (this.isFirstOne(index)) {
        label = this.$t('Carsharing.views.Booking.Location.origin_text');
      }

      if (this.isLastOne(index)) {
        label = this.$t('Carsharing.views.Booking.Location.destination_text');
      }

      return label;
    },
    getDataTestName(index, location, suffix) {
      const isNotFirstOne = this.isLastOne(index)
        ? 'destination'
        : 'middle';

      const positionByIndex = this.isFirstOne(index)
        ? 'origin'
        : isNotFirstOne;

      const suffixString = suffix ? `-${suffix}` : '';

      const dataTestName = `${DATA_TEST_ID}-${positionByIndex}${suffixString}`;
      const isInRange = inRange(index, 1, this.locations.length - 1);

      return isInRange
        ? `${dataTestName}_${truncate(location.address, { length: 10 })}`
        : dataTestName;
    },
  },

};
</script>

<template>
  <section class="LocationHeaderComponent mb-2">
    <div class="mb-1">
      <div
        v-for="(location, index) in locations"
        :key="index"
        :data-test-id="getDataTestName(index, location)"
        :class="[
          'd-flex align-items-center',
          {
            'mb-2 mb-sm-1': hasMultipleLocations
          },
        ]"
      >
        <img
          v-if="location.icon"
          :src="location.icon"
          :data-test-id="getDataTestName(index, location, 'icon')"
          :class="[
            'mr-2',
            {
              'cursor-pointer': isListening,
            }
          ]"
          alt="Location icon"
          @click="$emit('address-clicked', location)"
        >
        <i
          v-else
          :data-test-id="getDataTestName(index, location, 'no-icon')"
          class="invisible icons-medium-square mr-2"
        />

        <p class="row no-gutters flex-wrap align-items-center emobg-font-line-height-large mb-0">
          <strong
            v-if="hasMultipleLocations"
            :data-test-id="getDataTestName(index, location, 'label')"
            class="col-12 col-sm-auto emobg-font-weight-bold mr-1"
          >
            {{ getLabel(index) }}:
          </strong>
          <span
            :data-test-id="getDataTestName(index, location, 'address')"
            :class="[
              'col-12 col-sm-auto emobg-color-ink-light emobg-font-weight-normal mr-2',
              {
                'cursor-pointer': isListening,
              }
            ]"
            @click="$emit('address-clicked', location)"
          >
            {{ location.address }}
          </span>
          <a
            v-if="location.action"
            :data-test-id="getDataTestName(index, location, 'action')"
            href="#"
            class="
              col-12 col-sm-auto
              emobg-font-small
              emobg-color-primary text-decoration-none
            "
            @click.prevent="location.action"
          >
            {{ $t('Carsharing.views.Booking.Location.link_parking_info_text') }}
          </a>
        </p>
      </div>
      <div class="d-flex flex-wrap py-1">
        <slot name="extraInfo" />
      </div>
    </div>
  </section>
</template>
